$themes: (
'default': (
    'base' : $website,
    'link' : lighten($website, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($website, 40%),
    'basedark': lighten($website, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($website, 15%),
    'errorarrow' : lighten($website, 15%),
    'errortext' : lighten($website, 90%),
    'headerbg' : lighten($website, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($website, 60%),
    'headerborder' : lighten($website, 99%),
    'headeractiveborder' : lighten($website, 5%),
    'sidebarbg' : lighten($website, 5%),
    'sidebartext' : lighten($website, 99%),
    'sidebarlink' : lighten($website, 99%),
    'sidebarbadge' : lighten($website, 50%),
    'sidebarbadgetext' : lighten($website, 5%),
    'sidebartitle' : lighten($website, 90%),
    'sidebarborder' : lighten($website, 25%),
    'pageheaderbg' : lighten($website,  8%),
    'pageheadercolor' : lighten($website, 99%),
    'pageheaderlink' : lighten($website, 60%),
    'footerbg' : lighten($website, 10%),
    'footercolor' : lighten($website, 99%),
    'footerborder' : lighten($website, 10%),
    'footerlink' : lighten($website, 99%),
    'colorlight' : lighten($website, 70%),
    'colordark' : lighten($website, 20%),
    'gradient' : linear-gradient($website, #ea9271),
    'gradienthover' : linear-gradient(#ea9271, $website),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($website, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($website, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($website, 5%),
    'tablelink' : lighten($website, 1%),
    'btn' : linear-gradient(#ea9271, $website),
    'btncolor' : lighten($website, 95%),
    'btnhover' : linear-gradient($website, #ea9271),
    'btnactive' : linear-gradient($website, #ea9271),
    'btncurrent' : linear-gradient($website, #ea9271),
    'btnfocus' : linear-gradient($website, #ea9271),
    'btnborder' : lighten($website, 1%),
    'paginationbtn' : linear-gradient(#ea9271, $website),
    'paginationbtncolor' :  lighten($website, 95%),
    'paginationbtnhover' : linear-gradient($website, #ea9271),
    'paginationbtnhovertext' :  lighten($website, 95%),
    'paginationbtnactive' : linear-gradient($website, #ea9271),
    'paginationbtnactivetext' :  lighten($website, 95%),
    'paginationbtncurrent' : linear-gradient($website, #ea9271),
    'paginationbtncurrenttext' :  lighten($website, 95%),
    'paginationbtnfocus' : linear-gradient($website, #ea9271),
    'paginationbtnfocustext' :  lighten($website, 95%),
    'paginationbtnborder' : lighten($website, 1%),
    'colVisbtn' : linear-gradient(#ea9271, $website),
    'colVisbtncolor' :  lighten($website, 95%),
    'colVisbtnhover' : linear-gradient($website, #ea9271),
    'colVisbtnactive' : linear-gradient($website, #ea9271),
    'colVisbtncurrent' : linear-gradient($website, #ea9271),
    'colVisbtnfocus' : linear-gradient($website, #ea9271),
    'colVisbtnborder' : lighten($website, 1%),
    'selectbtn' :  lighten($website, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($website, 95%),
    'selectbtnactive' :  lighten($website, 95%),
    'selectbtncurrent' :  lighten($website, 95%),
    'selectbtnfocus' :  lighten($website, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($website, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($website, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($website, 5%),
    'paneltransbody' :  lighten($website, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($website, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($website, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($website, 95%),
    'panelgrayfooterborder' : lighten($website, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($website, 95%),
    'modalcontent' :  lighten($website, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($website, 5%),
    'modalheadercolor' : lighten($website, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($website, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($website, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($website, 69%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($website, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($website, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($website, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($website, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($website, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($website, 90%),
    'btndefault' : linear-gradient(#ea9271, $website),
    'btndefaulthover' : linear-gradient($website, #ea9271),
    'btndefaulttext' : lighten($website, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($website, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $website),
    'btnupdatetext' : lighten($website, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($website, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($website, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($website, 45%),
    'avatarborder' : lighten($website, 5%),
    'dropdownbg' : lighten($website, 90%),
    'dropdownli' : lighten($website, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($website, 5%),
    'dropdownhovercolor' : lighten($website, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($website, 5%),
    'dropdownarrow' : lighten($website, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($website, 85%),
    'datepickerhead' : lighten($website, 10%),
    'datepickerheadlink' : lighten($website, 90%),
    'datepickerheadtext' : lighten($website, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($website, 5%),
    'checkbox' : lighten($website, 5%),
    'datepickerweek' : lighten($website, 5%),
    'datepickerweektext' : lighten($website, 90%),
    'datepickerweeklink' : lighten($website, 90%),
    'tooltip' : lighten($website, 5%),
    'tooltiparrow' : lighten($website, 5%),
    'tooltiptext' : lighten($website, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($website, 90%),
    'userlogbg' : lighten($website, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($website, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($website, 90%),
    'taskdetailsidebarborder' : lighten($website, 25%),
    'taskdetailsidebartext' : lighten($website, 80%),
    'employeeproject' : lighten($website, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($website, 60%),
    'milestonenote' : darken($website, 60%),
    'milestonenotelabel': darken($website, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($website, 60%),
    'responsibleuser' : darken($website, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($website, 2%),
    'taskdetailtext' : lighten($website, 90%),
    'currentlisttitle' : darken($website, 60%),
    'currentlisttext' : darken($website, 60%),
    'currentlistlink' : darken($website, 2%),
    'currentlistfa' : darken($website, 60%),
    'completelisttitle' : darken($website, 60%),
    'completelisttext' : darken($website, 60%),
    'completelistlink' : darken($website, 2%),
    'completelistfa' : darken($website, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($website, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($website, 1%),
    'rolecolor' : lighten($website, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'green': (
    'base' : $logo_green,
    'link' : lighten($logo_green, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($logo_green, 40%),
    'basedark': lighten($logo_green, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($logo_green, 15%),
    'errorarrow' : lighten($logo_green, 15%),
    'errortext' : lighten($logo_green, 90%),
    'headerbg' : lighten($logo_green, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($logo_green, 60%),
    'headerborder' : lighten($logo_green, 99%),
    'headeractiveborder' : lighten($logo_green, 5%),
    'sidebarbg' : lighten($logo_green, 5%),
    'sidebartext' : lighten($logo_green, 99%),
    'sidebarlink' : lighten($logo_green, 99%),
    'sidebarbadge' : lighten($logo_green, 50%),
    'sidebarbadgetext' : lighten($logo_green, 5%),
    'sidebartitle' : lighten($logo_green, 90%),
    'sidebarborder' : lighten($logo_green, 25%),
    'pageheaderbg' : lighten($logo_green,  8%),
    'pageheadercolor' : lighten($logo_green, 99%),
    'pageheaderlink' : lighten($logo_green, 60%),
    'footerbg' : lighten($logo_green, 10%),
    'footercolor' : lighten($logo_green, 99%),
    'footerborder' : lighten($logo_green, 10%),
    'footerlink' : lighten($logo_green, 99%),
    'colorlight' : lighten($logo_green, 70%),
    'colordark' : lighten($logo_green, 20%),
    'gradient' : linear-gradient($logo_green, #13bec1),
    'gradienthover' : linear-gradient(#13bec1, $logo_green),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($logo_green, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($logo_green, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($logo_green, 5%),
    'tablelink' : lighten($logo_green, 1%),
    'btn' : linear-gradient(#13bec1, $logo_green),
    'btncolor' : lighten($logo_green, 95%),
    'btnhover' : linear-gradient($logo_green, #13bec1),
    'btnactive' : linear-gradient($logo_green, #13bec1),
    'btncurrent' : linear-gradient($logo_green, #13bec1),
    'btnfocus' : linear-gradient($logo_green, #13bec1),
    'btnborder' : lighten($logo_green, 1%),
    'paginationbtn' : linear-gradient(#13bec1, $logo_green),
    'paginationbtncolor' :  lighten($logo_green, 95%),
    'paginationbtnhover' : linear-gradient($logo_green, #13bec1),
    'paginationbtnhovertext' :  lighten($logo_green, 95%),
    'paginationbtnactive' : linear-gradient($logo_green, #13bec1),
    'paginationbtnactivetext' :  lighten($logo_green, 95%),
    'paginationbtncurrent' : linear-gradient($logo_green, #13bec1),
    'paginationbtncurrenttext' :  lighten($logo_green, 95%),
    'paginationbtnfocus' : linear-gradient($logo_green, #13bec1),
    'paginationbtnfocustext' :  lighten($logo_green, 95%),
    'paginationbtnborder' : lighten($logo_green, 1%),
    'colVisbtn' : linear-gradient(#13bec1, $logo_green),
    'colVisbtncolor' :  lighten($logo_green, 95%),
    'colVisbtnhover' : linear-gradient($logo_green, #13bec1),
    'colVisbtnactive' : linear-gradient($logo_green, #13bec1),
    'colVisbtncurrent' : linear-gradient($logo_green, #13bec1),
    'colVisbtnfocus' : linear-gradient($logo_green, #13bec1),
    'colVisbtnborder' : lighten($logo_green, 1%),
    'selectbtn' :  lighten($logo_green, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($logo_green, 95%),
    'selectbtnactive' :  lighten($logo_green, 95%),
    'selectbtncurrent' :  lighten($logo_green, 95%),
    'selectbtnfocus' :  lighten($logo_green, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($logo_green, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($logo_green, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($logo_green, 5%),
    'paneltransbody' :  lighten($logo_green, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($logo_green, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($logo_green, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($logo_green, 95%),
    'panelgrayfooterborder' : lighten($logo_green, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($logo_green, 95%),
    'modalcontent' :  lighten($logo_green, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($logo_green, 5%),
    'modalheadercolor' : lighten($logo_green, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($logo_green, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($logo_green, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($logo_green, 69%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($logo_green, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($logo_green, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($logo_green, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($logo_green, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($logo_green, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($logo_green, 90%),
    'btndefault' : linear-gradient(#13bec1, $logo_green),
    'btndefaulthover' : linear-gradient($logo_green, #13bec1),
    'btndefaulttext' : lighten($logo_green, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($logo_green, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $logo_green),
    'btnupdatetext' : lighten($logo_green, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($logo_green, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($logo_green, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($logo_green, 45%),
    'avatarborder' : lighten($logo_green, 5%),
    'dropdownbg' : lighten($logo_green, 90%),
    'dropdownli' : lighten($logo_green, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($logo_green, 5%),
    'dropdownhovercolor' : lighten($logo_green, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($logo_green, 5%),
    'dropdownarrow' : lighten($logo_green, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($logo_green, 85%),
    'datepickerhead' : lighten($logo_green, 10%),
    'datepickerheadlink' : lighten($logo_green, 90%),
    'datepickerheadtext' : lighten($logo_green, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($logo_green, 5%),
    'checkbox' : lighten($logo_green, 5%),
    'datepickerweek' : lighten($logo_green, 5%),
    'datepickerweektext' : lighten($logo_green, 90%),
    'datepickerweeklink' : lighten($logo_green, 90%),
    'tooltip' : lighten($logo_green, 5%),
    'tooltiparrow' : lighten($logo_green, 5%),
    'tooltiptext' : lighten($logo_green, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($logo_green, 90%),
    'userlogbg' : lighten($logo_green, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($logo_green, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($logo_green, 90%),
    'taskdetailsidebarborder' : lighten($logo_green, 25%),
    'taskdetailsidebartext' : lighten($logo_green, 80%),
    'employeeproject' : lighten($logo_green, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($logo_green, 60%),
    'milestonenote' : darken($logo_green, 60%),
    'milestonenotelabel': darken($logo_green, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($logo_green, 60%),
    'responsibleuser' : darken($logo_green, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($logo_green, 2%),
    'taskdetailtext' : lighten($logo_green, 90%),
    'currentlisttitle' : darken($logo_green, 60%),
    'currentlisttext' : darken($logo_green, 60%),
    'currentlistlink' : darken($logo_green, 2%),
    'currentlistfa' : darken($logo_green, 60%),
    'completelisttitle' : darken($logo_green, 60%),
    'completelisttext' : darken($logo_green, 60%),
    'completelistlink' : darken($logo_green, 2%),
    'completelistfa' : darken($logo_green, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($logo_green, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($logo_green, 1%),
    'rolecolor' : lighten($logo_green, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'red': (
    'base' : $logo_red,
    'link' : lighten($logo_red, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($logo_red, 40%),
    'basedark': lighten($logo_red, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($logo_red, 15%),
    'errorarrow' : lighten($logo_red, 15%),
    'errortext' : lighten($logo_red, 90%),
    'headerbg' : lighten($logo_red, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($logo_red, 60%),
    'headerborder' : lighten($logo_red, 99%),
    'headeractiveborder' : lighten($logo_red, 5%),
    'sidebarbg' : lighten($logo_red, 5%),
    'sidebartext' : lighten($logo_red, 99%),
    'sidebarlink' : lighten($logo_red, 99%),
    'sidebarbadge' : lighten($logo_red, 50%),
    'sidebarbadgetext' : lighten($logo_red, 5%),
    'sidebartitle' : lighten($logo_red, 90%),
    'sidebarborder' : lighten($logo_red, 25%),
    'pageheaderbg' : lighten($logo_red,  8%),
    'pageheadercolor' : lighten($logo_red, 99%),
    'pageheaderlink' : lighten($logo_red, 60%),
    'footerbg' : lighten($logo_red, 10%),
    'footercolor' : lighten($logo_red, 99%),
    'footerborder' : lighten($logo_red, 10%),
    'footerlink' : lighten($logo_red, 99%),
    'colorlight' : lighten($logo_red, 70%),
    'colordark' : lighten($logo_red, 20%),
    'gradient' : linear-gradient($logo_red, #e26568),
    'gradienthover' : linear-gradient(#e26568, $logo_red),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($logo_red, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($logo_red, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($logo_red, 5%),
    'tablelink' : lighten($logo_red, 1%),
    'btn' : linear-gradient(#e26568, $logo_red),
    'btncolor' : lighten($logo_red, 95%),
    'btnhover' : linear-gradient($logo_red, #e26568),
    'btnactive' : linear-gradient($logo_red, #e26568),
    'btncurrent' : linear-gradient($logo_red, #e26568),
    'btnfocus' : linear-gradient($logo_red, #e26568),
    'btnborder' : lighten($logo_red, 1%),
    'paginationbtn' : linear-gradient(#e26568, $logo_red),
    'paginationbtncolor' :  lighten($logo_red, 95%),
    'paginationbtnhover' : linear-gradient($logo_red, #e26568),
    'paginationbtnhovertext' :  lighten($logo_red, 95%),
    'paginationbtnactive' : linear-gradient($logo_red, #e26568),
    'paginationbtnactivetext' :  lighten($logo_red, 95%),
    'paginationbtncurrent' : linear-gradient($logo_red, #e26568),
    'paginationbtncurrenttext' :  lighten($logo_red, 95%),
    'paginationbtnfocus' : linear-gradient($logo_red, #e26568),
    'paginationbtnfocustext' :  lighten($logo_red, 95%),
    'paginationbtnborder' : lighten($logo_red, 1%),
    'colVisbtn' : linear-gradient(#e26568, $logo_red),
    'colVisbtncolor' :  lighten($logo_red, 95%),
    'colVisbtnhover' : linear-gradient($logo_red, #e26568),
    'colVisbtnactive' : linear-gradient($logo_red, #e26568),
    'colVisbtncurrent' : linear-gradient($logo_red, #e26568),
    'colVisbtnfocus' : linear-gradient($logo_red, #e26568),
    'colVisbtnborder' : lighten($logo_red, 1%),
    'selectbtn' :  lighten($logo_red, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($logo_red, 95%),
    'selectbtnactive' :  lighten($logo_red, 95%),
    'selectbtncurrent' :  lighten($logo_red, 95%),
    'selectbtnfocus' :  lighten($logo_red, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($logo_red, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($logo_red, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($logo_red, 5%),
    'paneltransbody' :  lighten($logo_red, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($logo_red, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($logo_red, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($logo_red, 95%),
    'panelgrayfooterborder' : lighten($logo_red, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($logo_red, 95%),
    'modalcontent' :  lighten($logo_red, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($logo_red, 5%),
    'modalheadercolor' : lighten($logo_red, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($logo_red, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($logo_red, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($logo_red, 62%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($logo_red, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($logo_red, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($logo_red, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($logo_red, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($logo_red, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($logo_red, 90%),
    'btndefault' : linear-gradient(#e26568, $logo_red),
    'btndefaulthover' : linear-gradient($logo_red, #e26568),
    'btndefaulttext' : lighten($logo_red, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($logo_red, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $logo_red),
    'btnupdatetext' : lighten($logo_red, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($logo_red, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($logo_red, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($logo_red, 45%),
    'avatarborder' : lighten($logo_red, 5%),
    'dropdownbg' : lighten($logo_red, 90%),
    'dropdownli' : lighten($logo_red, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($logo_red, 5%),
    'dropdownhovercolor' : lighten($logo_red, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($logo_red, 5%),
    'dropdownarrow' : lighten($logo_red, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($logo_red, 85%),
    'datepickerhead' : lighten($logo_red, 10%),
    'datepickerheadlink' : lighten($logo_red, 90%),
    'datepickerheadtext' : lighten($logo_red, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($logo_red, 5%),
    'checkbox' : lighten($logo_red, 5%),
    'datepickerweek' : lighten($logo_red, 5%),
    'datepickerweektext' : lighten($logo_red, 90%),
    'datepickerweeklink' : lighten($logo_red, 90%),
    'tooltip' : lighten($logo_red, 5%),
    'tooltiparrow' : lighten($logo_red, 5%),
    'tooltiptext' : lighten($logo_red, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($logo_red, 90%),
    'userlogbg' : lighten($logo_red, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($logo_red, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($logo_red, 90%),
    'taskdetailsidebarborder' : lighten($logo_red, 25%),
    'taskdetailsidebartext' : lighten($logo_red, 80%),
    'employeeproject' : lighten($logo_red, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($logo_red, 60%),
    'milestonenote' : darken($logo_red, 60%),
    'milestonenotelabel': darken($logo_red, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($logo_red, 60%),
    'responsibleuser' : darken($logo_red, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($logo_red, 2%),
    'taskdetailtext' : lighten($logo_red, 90%),
    'currentlisttitle' : darken($logo_red, 60%),
    'currentlisttext' : darken($logo_red, 60%),
    'currentlistlink' : darken($logo_red, 2%),
    'currentlistfa' : darken($logo_red, 60%),
    'completelisttitle' : darken($logo_red, 60%),
    'completelisttext' : darken($logo_red, 60%),
    'completelistlink' : darken($logo_red, 2%),
    'completelistfa' : darken($logo_red, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($logo_red, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($logo_red, 1%),
    'rolecolor' : lighten($logo_red, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'blue': (
    'base' : $logo_blue,
    'link' : lighten($logo_blue, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($logo_blue, 40%),
    'basedark': lighten($logo_blue, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($logo_blue, 15%),
    'errorarrow' : lighten($logo_blue, 15%),
    'errortext' : lighten($logo_blue, 90%),
    'headerbg' : lighten($logo_blue, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($logo_blue, 60%),
    'headerborder' : lighten($logo_blue, 99%),
    'headeractiveborder' : lighten($logo_blue, 5%),
    'sidebarbg' : lighten($logo_blue, 5%),
    'sidebartext' : lighten($logo_blue, 99%),
    'sidebarlink' : lighten($logo_blue, 99%),
    'sidebarbadge' : lighten($logo_blue, 50%),
    'sidebarbadgetext' : lighten($logo_blue, 5%),
    'sidebartitle' : lighten($logo_blue, 90%),
    'sidebarborder' : lighten($logo_blue, 25%),
    'pageheaderbg' : lighten($logo_blue,  8%),
    'pageheadercolor' : lighten($logo_blue, 99%),
    'pageheaderlink' : lighten($logo_blue, 60%),
    'footerbg' : lighten($logo_blue, 10%),
    'footercolor' : lighten($logo_blue, 99%),
    'footerborder' : lighten($logo_blue, 10%),
    'footerlink' : lighten($logo_blue, 99%),
    'colorlight' : lighten($logo_blue, 70%),
    'colordark' : lighten($logo_blue, 20%),
    'gradient' : linear-gradient($logo_blue, #2dc6ef),
    'gradienthover' : linear-gradient(#2dc6ef, $logo_blue),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($logo_blue, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($logo_blue, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($logo_blue, 5%),
    'tablelink' : lighten($logo_blue, 1%),
    'btn' : linear-gradient(#2dc6ef, $logo_blue),
    'btncolor' : lighten($logo_blue, 95%),
    'btnhover' : linear-gradient($logo_blue, #2dc6ef),
    'btnactive' : linear-gradient($logo_blue, #2dc6ef),
    'btncurrent' : linear-gradient($logo_blue, #2dc6ef),
    'btnfocus' : linear-gradient($logo_blue, #2dc6ef),
    'btnborder' : lighten($logo_blue, 1%),
    'paginationbtn' : linear-gradient(#2dc6ef, $logo_blue),
    'paginationbtncolor' :  lighten($logo_blue, 95%),
    'paginationbtnhover' : linear-gradient($logo_blue, #2dc6ef),
    'paginationbtnhovertext' :  lighten($logo_blue, 95%),
    'paginationbtnactive' : linear-gradient($logo_blue, #2dc6ef),
    'paginationbtnactivetext' :  lighten($logo_blue, 95%),
    'paginationbtncurrent' : linear-gradient($logo_blue, #2dc6ef),
    'paginationbtncurrenttext' :  lighten($logo_blue, 95%),
    'paginationbtnfocus' : linear-gradient($logo_blue, #2dc6ef),
    'paginationbtnfocustext' :  lighten($logo_blue, 95%),
    'paginationbtnborder' : lighten($logo_blue, 1%),
    'colVisbtn' : linear-gradient(#2dc6ef, $logo_blue),
    'colVisbtncolor' :  lighten($logo_blue, 95%),
    'colVisbtnhover' : linear-gradient($logo_blue, #2dc6ef),
    'colVisbtnactive' : linear-gradient($logo_blue, #2dc6ef),
    'colVisbtncurrent' : linear-gradient($logo_blue, #2dc6ef),
    'colVisbtnfocus' : linear-gradient($logo_blue, #2dc6ef),
    'colVisbtnborder' : lighten($logo_blue, 1%),
    'selectbtn' :  lighten($logo_blue, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($logo_blue, 95%),
    'selectbtnactive' :  lighten($logo_blue, 95%),
    'selectbtncurrent' :  lighten($logo_blue, 95%),
    'selectbtnfocus' :  lighten($logo_blue, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($logo_blue, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($logo_blue, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($logo_blue, 5%),
    'paneltransbody' :  lighten($logo_blue, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($logo_blue, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($logo_blue, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($dark, 95%),
    'panelgrayfooterborder' : lighten($logo_blue, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($logo_blue, 95%),
    'modalcontent' :  lighten($logo_blue, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($logo_blue, 5%),
    'modalheadercolor' : lighten($logo_blue, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($logo_blue, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($logo_blue, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($logo_blue, 62%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($logo_blue, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($logo_blue, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($logo_blue, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($logo_blue, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($logo_blue, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($logo_blue, 90%),
    'btndefault' : linear-gradient(#2dc6ef, $logo_blue),
    'btndefaulthover' : linear-gradient($logo_blue, #2dc6ef),
    'btndefaulttext' : lighten($logo_blue, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($logo_blue, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $logo_blue),
    'btnupdatetext' : lighten($logo_blue, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($logo_blue, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($logo_blue, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($logo_blue, 45%),
    'avatarborder' : lighten($logo_blue, 5%),
    'dropdownbg' : lighten($logo_blue, 90%),
    'dropdownli' : lighten($logo_blue, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($logo_blue, 5%),
    'dropdownhovercolor' : lighten($logo_blue, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($logo_blue, 5%),
    'dropdownarrow' : lighten($logo_blue, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($logo_blue, 85%),
    'datepickerhead' : lighten($logo_blue, 10%),
    'datepickerheadlink' : lighten($logo_blue, 90%),
    'datepickerheadtext' : lighten($logo_blue, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($logo_blue, 5%),
    'checkbox' : lighten($logo_blue, 5%),
    'datepickerweek' : lighten($logo_blue, 5%),
    'datepickerweektext' : lighten($logo_blue, 90%),
    'datepickerweeklink' : lighten($logo_blue, 90%),
    'tooltip' : lighten($logo_blue, 5%),
    'tooltiparrow' : lighten($logo_blue, 5%),
    'tooltiptext' : lighten($logo_blue, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($logo_blue, 90%),
    'userlogbg' : lighten($logo_blue, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($logo_blue, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($logo_blue, 90%),
    'taskdetailsidebarborder' : lighten($logo_blue, 25%),
    'taskdetailsidebartext' : lighten($logo_blue, 80%),
    'employeeproject' : lighten($logo_blue, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($logo_blue, 60%),
    'milestonenote' : darken($logo_blue, 60%),
    'milestonenotelabel': darken($logo_blue, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($logo_blue, 60%),
    'responsibleuser' : darken($logo_blue, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($logo_blue, 2%),
    'taskdetailtext' : lighten($logo_blue, 90%),
    'currentlisttitle' : darken($logo_blue, 60%),
    'currentlisttext' : darken($logo_blue, 60%),
    'currentlistlink' : darken($logo_blue, 2%),
    'currentlistfa' : darken($logo_blue, 60%),
    'completelisttitle' : darken($logo_blue, 60%),
    'completelisttext' : darken($logo_blue, 60%),
    'completelistlink' : darken($logo_blue, 2%),
    'completelistfa' : darken($logo_blue, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($logo_blue, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($logo_blue, 1%),
    'rolecolor' : lighten($logo_blue, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'yellow': (
    'base' : $logo_yellow,
    'link' : lighten($logo_yellow, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($logo_yellow, 40%),
    'basedark': lighten($logo_yellow, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($logo_yellow, 15%),
    'errorarrow' : lighten($logo_yellow, 15%),
    'errortext' : lighten($logo_yellow, 90%),
    'headerbg' : lighten($logo_yellow, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($logo_yellow, 60%),
    'headerborder' : lighten($logo_yellow, 99%),
    'headeractiveborder' : lighten($logo_yellow, 5%),
    'sidebarbg' : lighten($logo_yellow, 2%),
    'sidebartext' : lighten($logo_yellow, 99%),
    'sidebarlink' : lighten($logo_yellow, 99%),
    'sidebarbadge' : lighten($logo_yellow, 50%),
    'sidebarbadgetext' : lighten($logo_yellow, 5%),
    'sidebartitle' : lighten($logo_yellow, 90%),
    'sidebarborder' : lighten($logo_yellow, 25%),
    'pageheaderbg' : lighten($logo_yellow, 8%),
    'pageheadercolor' : lighten($logo_yellow, 99%),
    'pageheaderlink' : lighten($logo_yellow, 60%),
    'footerbg' : lighten($logo_yellow, 10%),
    'footercolor' : lighten($logo_yellow, 99%),
    'footerborder' : lighten($logo_yellow, 10%),
    'footerlink' : lighten($logo_yellow, 99%),
    'colorlight' : lighten($logo_yellow, 70%),
    'colordark' : lighten($logo_yellow, 20%),
    'gradient' : linear-gradient($logo_yellow, #f9d974),
    'gradienthover' : linear-gradient(#f9d974, $logo_yellow),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($logo_yellow, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($logo_yellow, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($logo_yellow, 5%),
    'tablelink' : lighten($logo_yellow, 1%),
    'btn' : linear-gradient(#f9d974, $logo_yellow),
    'btncolor' : lighten($logo_yellow, 95%),
    'btnhover' : linear-gradient($logo_yellow, #f9d974),
    'btnactive' : linear-gradient($logo_yellow, #f9d974),
    'btncurrent' : linear-gradient($logo_yellow, #f9d974),
    'btnfocus' : linear-gradient($logo_yellow, #f9d974),
    'btnborder' : lighten($logo_yellow, 1%),
    'paginationbtn' : linear-gradient(#f9d974, $logo_yellow),
    'paginationbtncolor' :  lighten($logo_yellow, 95%),
    'paginationbtnhover' : linear-gradient($logo_yellow, #f9d974),
    'paginationbtnhovertext' :  lighten($logo_yellow, 95%),
    'paginationbtnactive' : linear-gradient($logo_yellow, #f9d974),
    'paginationbtnactivetext' :  lighten($logo_yellow, 95%),
    'paginationbtncurrent' : linear-gradient($logo_yellow, #f9d974),
    'paginationbtncurrenttext' :  lighten($logo_yellow, 95%),
    'paginationbtnfocus' : linear-gradient($logo_yellow, #f9d974),
    'paginationbtnfocustext' :  lighten($logo_yellow, 95%),
    'paginationbtnborder' : lighten($logo_yellow, 1%),
    'colVisbtn' : linear-gradient(#f9d974, $logo_yellow),
    'colVisbtncolor' :  lighten($logo_yellow, 95%),
    'colVisbtnhover' : linear-gradient($logo_yellow, #f9d974),
    'colVisbtnactive' : linear-gradient($logo_yellow, #f9d974),
    'colVisbtncurrent' : linear-gradient($logo_yellow, #f9d974),
    'colVisbtnfocus' : linear-gradient($logo_yellow, #f9d974),
    'colVisbtnborder' : lighten($logo_yellow, 1%),
    'selectbtn' :  lighten($logo_yellow, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($logo_yellow, 95%),
    'selectbtnactive' :  lighten($logo_yellow, 95%),
    'selectbtncurrent' :  lighten($logo_yellow, 95%),
    'selectbtnfocus' :  lighten($logo_yellow, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($logo_yellow, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($logo_yellow, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($logo_yellow, 5%),
    'paneltransbody' :  lighten($logo_yellow, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($logo_yellow, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($logo_yellow, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($logo_yellow, 95%),
    'panelgrayfooterborder' : lighten($logo_yellow, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($logo_yellow, 95%),
    'modalcontent' :  lighten($logo_yellow, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($logo_yellow, 5%),
    'modalheadercolor' : lighten($logo_yellow, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($logo_yellow, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($logo_yellow, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($logo_yellow, 50%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($logo_yellow, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($logo_yellow, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($logo_yellow, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($logo_yellow, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($logo_yellow, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($logo_yellow, 90%),
    'btndefault' : linear-gradient(#f9d974, $logo_yellow),
    'btndefaulthover' : linear-gradient($logo_yellow, #f9d974),
    'btndefaulttext' : lighten($logo_yellow, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($logo_yellow, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $logo_yellow),
    'btnupdatetext' : lighten($logo_yellow, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($logo_yellow, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($logo_yellow, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($logo_yellow, 45%),
    'avatarborder' : lighten($logo_yellow, 5%),
    'dropdownbg' : lighten($logo_yellow, 90%),
    'dropdownli' : lighten($logo_yellow, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($logo_yellow, 5%),
    'dropdownhovercolor' : lighten($logo_yellow, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($logo_yellow, 5%),
    'dropdownarrow' : lighten($logo_yellow, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($logo_yellow, 85%),
    'datepickerhead' : lighten($logo_yellow, 10%),
    'datepickerheadlink' : lighten($logo_yellow, 90%),
    'datepickerheadtext' : lighten($logo_yellow, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($logo_yellow, 5%),
    'checkbox' : lighten($logo_yellow, 5%),
    'datepickerweek' : lighten($logo_yellow, 5%),
    'datepickerweektext' : lighten($logo_yellow, 90%),
    'datepickerweeklink' : lighten($logo_yellow, 90%),
    'tooltip' : lighten($logo_yellow, 5%),
    'tooltiparrow' : lighten($logo_yellow, 5%),
    'tooltiptext' : lighten($logo_yellow, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($logo_yellow, 90%),
    'userlogbg' : lighten($logo_yellow, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($logo_yellow, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($logo_yellow, 90%),
    'taskdetailsidebarborder' : lighten($logo_yellow, 25%),
    'taskdetailsidebartext' : lighten($logo_yellow, 80%),
    'employeeproject' : lighten($logo_yellow, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($logo_yellow, 60%),
    'milestonenote' : darken($logo_yellow, 60%),
    'milestonenotelabel': darken($logo_yellow, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($logo_yellow, 60%),
    'responsibleuser' : darken($logo_yellow, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($logo_yellow, 2%),
    'taskdetailtext' : lighten($logo_yellow, 90%),
    'currentlisttitle' : darken($logo_yellow, 60%),
    'currentlisttext' : darken($logo_yellow, 60%),
    'currentlistlink' : darken($logo_yellow, 2%),
    'currentlistfa' : darken($logo_yellow, 60%),
    'completelisttitle' : darken($logo_yellow, 60%),
    'completelisttext' : darken($logo_yellow, 60%),
    'completelistlink' : darken($logo_yellow, 2%),
    'completelistfa' : darken($logo_yellow, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($logo_yellow, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($logo_yellow, 1%),
    'rolecolor' : lighten($logo_yellow, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'black': (
    'base' : $black,
    'link' : lighten(#ad5b1d, 0%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($black, 50%),
    'bodybg' : lighten($black, 0%),
    'basetext' : lighten($black, 50%),
    'color' : lighten($black, 50%),
    'baselight': lighten($black, 99%),
    'basedark': lighten($black, 0%),
    'dark' : lighten($black, 0%),
    'light' : lighten($black, 99%),
    'formlabel' : lighten($black, 50%),
    'error' : lighten($black, 0%),
    'errorarrow' : lighten($black, 0%),
    'errortext' : lighten($black, 0%),
    'headerbg' : lighten($black, 20%),
    'headerlink' : lighten($black, 50%),
    'headertext' : lighten($black, 99%),
    'headerborder' : lighten($black, 20%),
    'headeractiveborder' : lighten($black, 10%),
    'sidebarbg' : lighten($black, 10%),
    'sidebartext' : lighten($black, 50%),
    'sidebarlink' : lighten($black, 50%),
    'sidebarbadge' : lighten($black, 50%),
    'sidebarbadgetext' : lighten($black, 0%),
    'sidebartitle' : lighten($black, 50%),
    'sidebarborder' : lighten($black, 20%),
    'pageheaderbg' : lighten($black, 15%),
    'pageheadercolor' : lighten($black, 99%),
    'pageheaderlink' : lighten($black, 99%),
    'footerbg' : lighten($black, 20%),
    'footercolor' : lighten($black, 50%),
    'footerborder' : lighten($black, 0%),
    'footerlink' : lighten($black, 50%),
    'colorlight' : lighten($black, 99%),
    'colordark' : lighten($black, 0%),
    'gradient' : linear-gradient(#151515, #3a3a3a),
    'gradienthover' : linear-gradient(#3a3a3a, #151515),
    'tablegradient' : linear-gradient(#3a3a3a, #151515),
    'tabletrodd' : lighten($black, 20%),
    'tabletreven' : lighten($black, 25%),
    'tablehover' : lighten($black, 30%),
    'tablehovercolor' : lighten($black, 80%),
    'tablehoverfa' : #47d60a,
    'tableth' : lighten($black, 50%),
    'tablecolor' : lighten($black, 50%),
    'tabactive' : lighten($black, 50%),
    'tableborder' : lighten($black, 30%),
    'tablearrow' : lighten($black, 50%),
    'tablelink' : lighten(#ad5b1d, 0%),
    'btn' : linear-gradient(#3a3a3a, #151515),
    'btncolor' : lighten($black, 50%),
    'btnhover' : linear-gradient(#151515, #3a3a3a),
    'btnactive' : linear-gradient(#151515, #3a3a3a),
    'btncurrent' : linear-gradient(#151515, #3a3a3a),
    'btnfocus' : linear-gradient(#151515, #3a3a3a),
    'btnborder' : lighten($black, 0%),
    'paginationbtn' : linear-gradient(#848181, #565656),
    'paginationbtncolor' :  lighten($black, 99%),
    'paginationbtnhover' : linear-gradient(#214c0e, #4e8f32),
    'paginationbtnhovertext' :  lighten($black, 99%),
    'paginationbtnactive' : linear-gradient(#214c0e, #4e8f32),
    'paginationbtnactivetext' :  lighten($black, 99%),
    'paginationbtncurrent' : linear-gradient(#214c0e, #4e8f32),
    'paginationbtncurrenttext' :  lighten($black, 99%),
    'paginationbtnfocus' : linear-gradient(#214c0e, #4e8f32),
    'paginationbtnfocustext' :  lighten($black, 99%),
    'paginationbtnborder' : lighten($black, 99%),
    'colVisbtn' : linear-gradient(#3a3a3a, #151515),
    'colVisbtncolor' :  lighten($black, 50%),
    'colVisbtnhover' : linear-gradient(#151515, #3a3a3a),
    'colVisbtnactive' : linear-gradient(#151515, #3a3a3a),
    'colVisbtncurrent' : linear-gradient(#151515, #3a3a3a),
    'colVisbtnfocus' : linear-gradient(#151515, #3a3a3a),
    'colVisbtnborder' : lighten($black, 0%),
    'selectbtn' :  lighten($black, 15%),
    'selectbtncolor' : lighten($black, 50%),
    'selectbtnhover' :  lighten($black, 15%),
    'selectbtnactive' :  lighten($black, 15%),
    'selectbtncurrent' :  lighten($black, 15%),
    'selectbtnfocus' :  lighten($black, 15%),
    'selectbtnborder' : lighten($black, 20%),
    'paneltrans' :  lighten($black, 40%),
    'paneltranstitle' : lighten($black, 50%),
    'paneltransborder' : lighten($black, 20%),
    'paneltransheading' :  lighten($black, 10%),
    'paneltransheadingborder' : lighten($black, 50%),
    'paneltransheadinglink' : lighten($black, 50%),
    'paneltransbody' :  lighten($black, 10%),
    'paneltransbodytext' : lighten($black, 50%),
    'paneltransbodyborder' : lighten($black, 60%),
    'paneltransfooter' : lighten($black, 10%),
    'paneltransfooterborder' : lighten($black, 20%),
    'paneltransfootertext' : lighten($black, 50%),
    'panelgray' : lighten($black, 10%),
    'panelgraytitle' : lighten($black, 50%),
    'panelgrayborder' : lighten($black, 25%),
    'panelgrayheading' : lighten($black, 15%),
    'panelgrayheadinglink' : lighten($black, 50%),
    'panelgrayheadingborder' : lighten($black, 25%),
    'panelgraybody' :  lighten($black, 20%),
    'panelgraybodytext' : lighten($black, 50%),
    'panelgraybodyborder' : lighten($black, 25%),
    'panelgrayfooter' : lighten($black, 20%),
    'panelgrayfooterborder' : lighten($black, 25%),
    'panelgrayfootertext' : lighten($black, 50%),
    'modal' :  lighten($black, 10%),
    'modalcontent' :  lighten($black, 10%),
    'modalbackdrop' : lighten($black, 0%),
    'modalheaderbg' : lighten($black, 10%),
    'modalheadertext' : lighten($black, 50%),
    'modalheadercolor' : lighten($black, 50%),
    'modalheaderborder' : lighten($black, 30%),
    'modalbody' :  lighten($black, 10%),
    'modalbodytext' : lighten($black, 50%),
    'modalbodylink' : lighten(#ad5b1d, 0%),
    'modalfooterbg' : lighten($black, 10%),
    'modalfooterborder' : lighten($black, 30%),
    'modalfootertext' : lighten($black, 50%),
    'tabsborder' : lighten($black, 20%),
    'tabbg' : lighten($black, 20%),
    'tabtext' : lighten($black, 50%),
    'tabsborderactive' : lighten(#ad5b1d, 0%),
    'tabbgactive' : lighten($black, 62%),
    'tabtextactive' : lighten(#ad5b1d, 50%),
    'lihover' : lighten($black, 15%),
    'lihovertext' : lighten($black, 50%),
    'libg' : lighten($black, 10%),
    'liborder' : lighten($black, 20%),
    'btnedit' : linear-gradient(#676767, #808080),
    'btnedithover' : linear-gradient(#808080, #676767),
    'btnedittext' : lighten($black, 0%),
    'btnview' : linear-gradient(#676767, #808080),
    'btnviewhover' : linear-gradient(#808080, #676767),
    'btnviewtext' : lighten($black, 0%),
    'btndelete' : linear-gradient(#676767, #808080),
    'btndeletehover' : linear-gradient(#808080, #676767),
    'btndeletetext' : lighten($black, 0%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($black, 99%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($black, 99%),
    'btndefault' : linear-gradient(#673c1d, #331905),
    'btndefaulthover' : linear-gradient(#331905, #673c1d),
    'btndefaulttext' : lighten($black, 99%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($black, 99%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $black),
    'btnupdatetext' : lighten($black, 0%),
    'breadcrumb' : lighten($black, 10%),
    'breadcrumbactive' : lighten($black, 50%),
    'breadcrumbborder' : lighten($black, 10%),
    'breadcrumblink' : lighten($black, 50%),
    'breadcrumbcolor' : lighten($black, 50%),
    'formcontrol' : lighten($black, 15%),
    'formcontrolborder' : lighten($black, 20%),
    'formcontrolfocus' : lighten($black, 20%),
    'formcontroltext' : lighten($black, 30%),
    'inputaddon' : lighten($black, 18%),
    'inputaddonicon' : lighten($black, 22%),
    'avatar' : lighten($black, 20%),
    'avatarborder' : lighten($black, 20%),
    'dropdownbg' : lighten($black, 5%),
    'dropdownli' : lighten($black, 10%),
    'dropdownborder' : lighten($black, 5%),
    'dropdownhover' : lighten($black, 15%),
    'dropdownhovercolor' : lighten($black, 50%),
    'dropdowncolor' : lighten($black, 50%),
    'dropdownactive' : lighten($black, 0%),
    'dropdownarrow' : lighten($black, 10%),
    'dropdownarrowshadow' : lighten($black, 10%),
    'datepickerbg' : lighten($black, 2%),
    'datepickerhead' : lighten($black, 15%),
    'datepickerheadlink' : lighten($black, 50%),
    'datepickerheadtext' : lighten($black, 50%),
    'datesborder' : lighten($black, 10%),
    'radio' : lighten($black, 50%),
    'checkbox' : lighten($black, 50%),
    'datepickerweek' : lighten($black, 20%),
    'datepickerweektext' : lighten($black, 50%),
    'datepickerweeklink' : lighten($black, 50%),
    'tooltip' : lighten($black, 0%),
    'tooltiparrow' : lighten($black, 0%),
    'tooltiptext' : lighten($black, 0%),
    'filterbg' : lighten($black, 15%),
    'filterborder' : lighten($black, 15%),
    'filtertotallabel' : lighten($black, 50%),
    'filtertotalstrong' : lighten($black, 50%),
    'filtertext' : lighten($black, 50%),
    'agodaystext' : lighten($black, 20%),
    'userlogbg' : lighten($black, 10%),
    'userlogname' : lighten($black, 50%),
    'userlogdesignation' : lighten($black, 50%),
    'userlogmailid' : lighten(#ad5b1d, 0%),
    'userlogborder' : lighten($black, 20%),
    'taskdetailsidebarlink' : lighten($black, 50%),
    'taskdetailsidebarborder' : lighten($black, 15%),
    'taskdetailsidebartext' : lighten($black, 50%),
    'employeeproject' : lighten($black, 10%),
    'employeeprojecttitle' : lighten($black, 50%),
    'employeeprojectborder' : lighten($black, 20%),
    'everythingdate' : lighten($black, 15%),
    'everythingdatetext' : lighten($black, 50%),
    'taskdetailupadte' : lighten($black, 50%),
    'taskdetailfa' : lighten($black, 50%),
    'milestonenote' : lighten($black, 50%),
    'milestonenotelabel': lighten($black, 50%),
    'milestoneborder' : lighten($black, 10%),
    'responsiblelabel' : lighten($black, 50%),
    'responsibleuser' : lighten($black, 50%),
    'taskdetailborder' : lighten($black, 10%),
    'taskdetaillink' : lighten($black, 0%),
    'taskdetailtext' : lighten($black, 0%),
    'currentlisttitle' : lighten($black, 0%),
    'currentlisttext' : lighten($black, 0%),
    'currentlistlink' : lighten($black, 0%),
    'currentlistfa' : lighten($black, 0%),
    'completelisttitle' : lighten($black, 0%),
    'completelisttext' : lighten($black, 0%),
    'completelistlink' : lighten($black, 0%),
    'completelistfa' : lighten($black, 0%),
    'taskaddlistborder' : lighten($black, 0%),
    'taskaddlisticontext' :  lighten($black, 0%),
    'taskaddlisticonbg' :  lighten($black, 0%),
    'taskaddlisticonborder' :  lighten($black, 0%),
    'filtertotaltimeborder' : lighten($black, 0%),
    'viewuserprofileborder' : lighten($black, 0%),
    'rolebg' : lighten($black, 20%),
    'rolecolor' : lighten($black, 50%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'facebook': (
    'base' : $facebook,
    'link' : lighten($facebook, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($facebook, 40%),
    'basedark': lighten($facebook, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($facebook, 15%),
    'errorarrow' : lighten($facebook, 15%),
    'errortext' : lighten($facebook, 90%),
    'headerbg' : lighten($facebook, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($facebook, 60%),
    'headerborder' : lighten($facebook, 99%),
    'headeractiveborder' : lighten($facebook, 5%),
    'sidebarbg' : lighten($facebook, 5%),
    'sidebartext' : lighten($facebook, 99%),
    'sidebarlink' : lighten($facebook, 99%),
    'sidebarbadge' : lighten($facebook, 50%),
    'sidebarbadgetext' : lighten($facebook, 5%),
    'sidebartitle' : lighten($facebook, 90%),
    'sidebarborder' : lighten($facebook, 25%),
    'pageheaderbg' : lighten($facebook,  8%),
    'pageheadercolor' : lighten($facebook, 99%),
    'pageheaderlink' : lighten($facebook, 60%),
    'footerbg' : lighten($facebook, 10%),
    'footercolor' : lighten($facebook, 99%),
    'footerborder' : lighten($facebook, 10%),
    'footerlink' : lighten($facebook, 99%),
    'colorlight' : lighten($facebook, 70%),
    'colordark' : lighten($facebook, 20%),
    'gradient' : linear-gradient($facebook, #4267b2),
    'gradienthover' : linear-gradient(#4267b2, $facebook),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($facebook, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($facebook, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($facebook, 5%),
    'tablelink' : lighten($facebook, 1%),
    'btn' : linear-gradient(#4267b2, $facebook),
    'btncolor' : lighten($facebook, 95%),
    'btnhover' : linear-gradient($facebook, #4267b2),
    'btnactive' : linear-gradient($facebook, #4267b2),
    'btncurrent' : linear-gradient($facebook, #4267b2),
    'btnfocus' : linear-gradient($facebook, #4267b2),
    'btnborder' : lighten($facebook, 1%),
    'paginationbtn' : linear-gradient(#4267b2, $facebook),
    'paginationbtncolor' :  lighten($facebook, 95%),
    'paginationbtnhover' : linear-gradient($facebook, #4267b2),
    'paginationbtnhovertext' :  lighten($facebook, 95%),
    'paginationbtnactive' : linear-gradient($facebook, #4267b2),
    'paginationbtnactivetext' :  lighten($facebook, 95%),
    'paginationbtncurrent' : linear-gradient($facebook, #4267b2),
    'paginationbtncurrenttext' :  lighten($facebook, 95%),
    'paginationbtnfocus' : linear-gradient($facebook, #4267b2),
    'paginationbtnfocustext' :  lighten($facebook, 95%),
    'paginationbtnborder' : lighten($facebook, 1%),
    'colVisbtn' : linear-gradient(#4267b2, $facebook),
    'colVisbtncolor' :  lighten($facebook, 95%),
    'colVisbtnhover' : linear-gradient($facebook, #4267b2),
    'colVisbtnactive' : linear-gradient($facebook, #4267b2),
    'colVisbtncurrent' : linear-gradient($facebook, #4267b2),
    'colVisbtnfocus' : linear-gradient($facebook, #4267b2),
    'colVisbtnborder' : lighten($facebook, 1%),
    'selectbtn' :  lighten($facebook, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($facebook, 95%),
    'selectbtnactive' :  lighten($facebook, 95%),
    'selectbtncurrent' :  lighten($facebook, 95%),
    'selectbtnfocus' :  lighten($facebook, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($facebook, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($facebook, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($facebook, 5%),
    'paneltransbody' :  lighten($facebook, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($facebook, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($facebook, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($facebook, 95%),
    'panelgrayfooterborder' : lighten($facebook, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($facebook, 95%),
    'modalcontent' :  lighten($facebook, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($facebook, 5%),
    'modalheadercolor' : lighten($facebook, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($facebook, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($facebook, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($facebook, 69%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($facebook, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($facebook, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($facebook, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($facebook, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($facebook, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($facebook, 90%),
    'btndefault' : linear-gradient(#4267b2, $facebook),
    'btndefaulthover' : linear-gradient($facebook, #4267b2),
    'btndefaulttext' : lighten($facebook, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($facebook, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $facebook),
    'btnupdatetext' : lighten($facebook, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($facebook, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($facebook, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($facebook, 45%),
    'avatarborder' : lighten($facebook, 5%),
    'dropdownbg' : lighten($facebook, 90%),
    'dropdownli' : lighten($facebook, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($facebook, 5%),
    'dropdownhovercolor' : lighten($facebook, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($facebook, 5%),
    'dropdownarrow' : lighten($facebook, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($facebook, 85%),
    'datepickerhead' : lighten($facebook, 10%),
    'datepickerheadlink' : lighten($facebook, 90%),
    'datepickerheadtext' : lighten($facebook, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($facebook, 5%),
    'checkbox' : lighten($facebook, 5%),
    'datepickerweek' : lighten($facebook, 5%),
    'datepickerweektext' : lighten($facebook, 90%),
    'datepickerweeklink' : lighten($facebook, 90%),
    'tooltip' : lighten($facebook, 5%),
    'tooltiparrow' : lighten($facebook, 5%),
    'tooltiptext' : lighten($facebook, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($facebook, 90%),
    'userlogbg' : lighten($facebook, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($facebook, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($facebook, 90%),
    'taskdetailsidebarborder' : lighten($facebook, 25%),
    'taskdetailsidebartext' : lighten($facebook, 80%),
    'employeeproject' : lighten($facebook, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($facebook, 60%),
    'milestonenote' : darken($facebook, 60%),
    'milestonenotelabel': darken($facebook, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($facebook, 60%),
    'responsibleuser' : darken($facebook, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($facebook, 2%),
    'taskdetailtext' : lighten($facebook, 90%),
    'currentlisttitle' : darken($facebook, 60%),
    'currentlisttext' : darken($facebook, 60%),
    'currentlistlink' : darken($facebook, 2%),
    'currentlistfa' : darken($facebook, 60%),
    'completelisttitle' : darken($facebook, 60%),
    'completelisttext' : darken($facebook, 60%),
    'completelistlink' : darken($facebook, 2%),
    'completelistfa' : darken($facebook, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($facebook, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($facebook, 1%),
    'rolecolor' : lighten($facebook, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'twitter': (
    'base' : $twitter,
    'link' : lighten($twitter, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($twitter, 40%),
    'basedark': lighten($twitter, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($twitter, 15%),
    'errorarrow' : lighten($twitter, 15%),
    'errortext' : lighten($twitter, 90%),
    'headerbg' : lighten($twitter, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($twitter, 60%),
    'headerborder' : lighten($twitter, 99%),
    'headeractiveborder' : lighten($twitter, 5%),
    'sidebarbg' : lighten($twitter, 5%),
    'sidebartext' : lighten($twitter, 99%),
    'sidebarlink' : lighten($twitter, 99%),
    'sidebarbadge' : lighten($twitter, 50%),
    'sidebarbadgetext' : lighten($twitter, 5%),
    'sidebartitle' : lighten($twitter, 90%),
    'sidebarborder' : lighten($twitter, 25%),
    'pageheaderbg' : lighten($twitter,  8%),
    'pageheadercolor' : lighten($twitter, 99%),
    'pageheaderlink' : lighten($twitter, 60%),
    'footerbg' : lighten($twitter, 10%),
    'footercolor' : lighten($twitter, 99%),
    'footerborder' : lighten($twitter, 10%),
    'footerlink' : lighten($twitter, 99%),
    'colorlight' : lighten($twitter, 70%),
    'colordark' : lighten($twitter, 20%),
    'gradient' : linear-gradient($twitter, $twitter),
    'gradienthover' : linear-gradient($twitter, $twitter),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($twitter, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($twitter, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($twitter, 5%),
    'tablelink' : lighten($twitter, 1%),
    'btn' : linear-gradient($twitter, $twitter),
    'btncolor' : lighten($twitter, 95%),
    'btnhover' : linear-gradient($twitter, $twitter),
    'btnactive' : linear-gradient($twitter, $twitter),
    'btncurrent' : linear-gradient($twitter, $twitter),
    'btnfocus' : linear-gradient($twitter, $twitter),
    'btnborder' : lighten($twitter, 1%),
    'paginationbtn' : linear-gradient($twitter, $twitter),
    'paginationbtncolor' :  lighten($twitter, 95%),
    'paginationbtnhover' : linear-gradient($twitter, $twitter),
    'paginationbtnhovertext' :  lighten($twitter, 95%),
    'paginationbtnactive' : linear-gradient($twitter, $twitter),
    'paginationbtnactivetext' :  lighten($twitter, 95%),
    'paginationbtncurrent' : linear-gradient($twitter, $twitter),
    'paginationbtncurrenttext' :  lighten($twitter, 95%),
    'paginationbtnfocus' : linear-gradient($twitter, $twitter),
    'paginationbtnfocustext' :  lighten($twitter, 95%),
    'paginationbtnborder' : lighten($twitter, 1%),
    'colVisbtn' : linear-gradient($twitter, $twitter),
    'colVisbtncolor' :  lighten($twitter, 95%),
    'colVisbtnhover' : linear-gradient($twitter, $twitter),
    'colVisbtnactive' : linear-gradient($twitter, $twitter),
    'colVisbtncurrent' : linear-gradient($twitter, $twitter),
    'colVisbtnfocus' : linear-gradient($twitter, $twitter),
    'colVisbtnborder' : lighten($twitter, 1%),
    'selectbtn' :  lighten($twitter, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($twitter, 95%),
    'selectbtnactive' :  lighten($twitter, 95%),
    'selectbtncurrent' :  lighten($twitter, 95%),
    'selectbtnfocus' :  lighten($twitter, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($twitter, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($twitter, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($twitter, 5%),
    'paneltransbody' :  lighten($twitter, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($twitter, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($twitter, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($twitter, 95%),
    'panelgrayfooterborder' : lighten($twitter, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($twitter, 95%),
    'modalcontent' :  lighten($twitter, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($twitter, 5%),
    'modalheadercolor' : lighten($twitter, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($twitter, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($twitter, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($twitter, 69%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($twitter, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($twitter, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($twitter, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($twitter, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($twitter, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($twitter, 90%),
    'btndefault' : linear-gradient($twitter, $twitter),
    'btndefaulthover' : linear-gradient($twitter, $twitter),
    'btndefaulttext' : lighten($twitter, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($twitter, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $twitter),
    'btnupdatetext' : lighten($twitter, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($twitter, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($twitter, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($twitter, 45%),
    'avatarborder' : lighten($twitter, 5%),
    'dropdownbg' : lighten($twitter, 90%),
    'dropdownli' : lighten($twitter, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($twitter, 5%),
    'dropdownhovercolor' : lighten($twitter, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($twitter, 5%),
    'dropdownarrow' : lighten($twitter, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($twitter, 85%),
    'datepickerhead' : lighten($twitter, 10%),
    'datepickerheadlink' : lighten($twitter, 90%),
    'datepickerheadtext' : lighten($twitter, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($twitter, 5%),
    'checkbox' : lighten($twitter, 5%),
    'datepickerweek' : lighten($twitter, 5%),
    'datepickerweektext' : lighten($twitter, 90%),
    'datepickerweeklink' : lighten($twitter, 90%),
    'tooltip' : lighten($twitter, 5%),
    'tooltiparrow' : lighten($twitter, 5%),
    'tooltiptext' : lighten($twitter, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($twitter, 90%),
    'userlogbg' : lighten($twitter, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($twitter, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($twitter, 90%),
    'taskdetailsidebarborder' : lighten($twitter, 25%),
    'taskdetailsidebartext' : lighten($twitter, 80%),
    'employeeproject' : lighten($twitter, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($twitter, 60%),
    'milestonenote' : darken($twitter, 60%),
    'milestonenotelabel': darken($twitter, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($twitter, 60%),
    'responsibleuser' : darken($twitter, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($twitter, 2%),
    'taskdetailtext' : lighten($twitter, 90%),
    'currentlisttitle' : darken($twitter, 60%),
    'currentlisttext' : darken($twitter, 60%),
    'currentlistlink' : darken($twitter, 2%),
    'currentlistfa' : darken($twitter, 60%),
    'completelisttitle' : darken($twitter, 60%),
    'completelisttext' : darken($twitter, 60%),
    'completelistlink' : darken($twitter, 2%),
    'completelistfa' : darken($twitter, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($twitter, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($twitter, 1%),
    'rolecolor' : lighten($twitter, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'linkedin': (
    'base' : $linkedin,
    'link' : lighten($linkedin, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($linkedin, 40%),
    'basedark': lighten($linkedin, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($linkedin, 15%),
    'errorarrow' : lighten($linkedin, 15%),
    'errortext' : lighten($linkedin, 90%),
    'headerbg' : lighten($linkedin, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($linkedin, 60%),
    'headerborder' : lighten($linkedin, 99%),
    'headeractiveborder' : lighten($linkedin, 5%),
    'sidebarbg' : lighten($linkedin, 5%),
    'sidebartext' : lighten($linkedin, 99%),
    'sidebarlink' : lighten($linkedin, 99%),
    'sidebarbadge' : lighten($linkedin, 50%),
    'sidebarbadgetext' : lighten($linkedin, 5%),
    'sidebartitle' : lighten($linkedin, 90%),
    'sidebarborder' : lighten($linkedin, 25%),
    'pageheaderbg' : lighten($linkedin,  8%),
    'pageheadercolor' : lighten($linkedin, 99%),
    'pageheaderlink' : lighten($linkedin, 60%),
    'footerbg' : lighten($linkedin, 10%),
    'footercolor' : lighten($linkedin, 99%),
    'footerborder' : lighten($linkedin, 10%),
    'footerlink' : lighten($linkedin, 99%),
    'colorlight' : lighten($linkedin, 70%),
    'colordark' : lighten($linkedin, 20%),
    'gradient' : linear-gradient($linkedin, $linkedin),
    'gradienthover' : linear-gradient($linkedin, $linkedin),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($linkedin, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($linkedin, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($linkedin, 5%),
    'tablelink' : lighten($linkedin, 1%),
    'btn' : linear-gradient($linkedin, $linkedin),
    'btncolor' : lighten($linkedin, 95%),
    'btnhover' : linear-gradient($linkedin, $linkedin),
    'btnactive' : linear-gradient($linkedin, $linkedin),
    'btncurrent' : linear-gradient($linkedin, $linkedin),
    'btnfocus' : linear-gradient($linkedin, $linkedin),
    'btnborder' : lighten($linkedin, 1%),
    'paginationbtn' : linear-gradient($linkedin, $linkedin),
    'paginationbtncolor' :  lighten($linkedin, 95%),
    'paginationbtnhover' : linear-gradient($linkedin, $linkedin),
    'paginationbtnhovertext' :  lighten($linkedin, 95%),
    'paginationbtnactive' : linear-gradient($linkedin, $linkedin),
    'paginationbtnactivetext' :  lighten($linkedin, 95%),
    'paginationbtncurrent' : linear-gradient($linkedin, $linkedin),
    'paginationbtncurrenttext' :  lighten($linkedin, 95%),
    'paginationbtnfocus' : linear-gradient($linkedin, $linkedin),
    'paginationbtnfocustext' :  lighten($linkedin, 95%),
    'paginationbtnborder' : lighten($linkedin, 1%),
    'colVisbtn' : linear-gradient($linkedin, $linkedin),
    'colVisbtncolor' :  lighten($linkedin, 95%),
    'colVisbtnhover' : linear-gradient($linkedin, $linkedin),
    'colVisbtnactive' : linear-gradient($linkedin, $linkedin),
    'colVisbtncurrent' : linear-gradient($linkedin, $linkedin),
    'colVisbtnfocus' : linear-gradient($linkedin, $linkedin),
    'colVisbtnborder' : lighten($linkedin, 1%),
    'selectbtn' :  lighten($linkedin, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($linkedin, 95%),
    'selectbtnactive' :  lighten($linkedin, 95%),
    'selectbtncurrent' :  lighten($linkedin, 95%),
    'selectbtnfocus' :  lighten($linkedin, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($linkedin, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($linkedin, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($linkedin, 5%),
    'paneltransbody' :  lighten($linkedin, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($linkedin, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($linkedin, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($linkedin, 95%),
    'panelgrayfooterborder' : lighten($linkedin, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($linkedin, 95%),
    'modalcontent' :  lighten($linkedin, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($linkedin, 5%),
    'modalheadercolor' : lighten($linkedin, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($linkedin, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($linkedin, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($linkedin, 69%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($linkedin, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($linkedin, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($linkedin, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($linkedin, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($linkedin, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($linkedin, 90%),
    'btndefault' : linear-gradient($linkedin, $linkedin),
    'btndefaulthover' : linear-gradient($linkedin, $linkedin),
    'btndefaulttext' : lighten($linkedin, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($linkedin, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $linkedin),
    'btnupdatetext' : lighten($linkedin, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($linkedin, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($linkedin, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($linkedin, 45%),
    'avatarborder' : lighten($linkedin, 5%),
    'dropdownbg' : lighten($linkedin, 90%),
    'dropdownli' : lighten($linkedin, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($linkedin, 5%),
    'dropdownhovercolor' : lighten($linkedin, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($linkedin, 5%),
    'dropdownarrow' : lighten($linkedin, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($linkedin, 85%),
    'datepickerhead' : lighten($linkedin, 10%),
    'datepickerheadlink' : lighten($linkedin, 90%),
    'datepickerheadtext' : lighten($linkedin, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($linkedin, 5%),
    'checkbox' : lighten($linkedin, 5%),
    'datepickerweek' : lighten($linkedin, 5%),
    'datepickerweektext' : lighten($linkedin, 90%),
    'datepickerweeklink' : lighten($linkedin, 90%),
    'tooltip' : lighten($linkedin, 5%),
    'tooltiparrow' : lighten($linkedin, 5%),
    'tooltiptext' : lighten($linkedin, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($linkedin, 90%),
    'userlogbg' : lighten($linkedin, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($linkedin, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($linkedin, 90%),
    'taskdetailsidebarborder' : lighten($linkedin, 25%),
    'taskdetailsidebartext' : lighten($linkedin, 80%),
    'employeeproject' : lighten($linkedin, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($linkedin, 60%),
    'milestonenote' : darken($linkedin, 60%),
    'milestonenotelabel': darken($linkedin, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($linkedin, 60%),
    'responsibleuser' : darken($linkedin, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($linkedin, 2%),
    'taskdetailtext' : lighten($linkedin, 90%),
    'currentlisttitle' : darken($linkedin, 60%),
    'currentlisttext' : darken($linkedin, 60%),
    'currentlistlink' : darken($linkedin, 2%),
    'currentlistfa' : darken($linkedin, 60%),
    'completelisttitle' : darken($linkedin, 60%),
    'completelisttext' : darken($linkedin, 60%),
    'completelistlink' : darken($linkedin, 2%),
    'completelistfa' : darken($linkedin, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($linkedin, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($linkedin, 1%),
    'rolecolor' : lighten($linkedin, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
'google': (
    'base' : $google,
    'link' : lighten($google, 1%),
    'agodays' : #ebc347,
    'fixhours' : #616161,
    'border' : lighten($dark, 98%),
    'bodybg' : lighten($dark, 98%),
    'basetext' : lighten($dark, 40%),
    'color' : lighten($dark, 40%),
    'baselight': lighten($google, 40%),
    'basedark': lighten($google, 20%),
    'dark' : lighten($dark, 40%),
    'light' : lighten($dark, 95%),
    'formlabel' : lighten($dark, 30%),
    'error' : lighten($google, 15%),
    'errorarrow' : lighten($google, 15%),
    'errortext' : lighten($google, 90%),
    'headerbg' : lighten($google, 95%),
    'headerlink' : lighten($dark, 5%),
    'headertext' : lighten($google, 60%),
    'headerborder' : lighten($google, 99%),
    'headeractiveborder' : lighten($google, 5%),
    'sidebarbg' : lighten($google, 5%),
    'sidebartext' : lighten($google, 99%),
    'sidebarlink' : lighten($google, 99%),
    'sidebarbadge' : lighten($google, 50%),
    'sidebarbadgetext' : lighten($google, 5%),
    'sidebartitle' : lighten($google, 90%),
    'sidebarborder' : lighten($google, 25%),
    'pageheaderbg' : lighten($google,  8%),
    'pageheadercolor' : lighten($google, 99%),
    'pageheaderlink' : lighten($google, 60%),
    'footerbg' : lighten($google, 10%),
    'footercolor' : lighten($google, 99%),
    'footerborder' : lighten($google, 10%),
    'footerlink' : lighten($google, 99%),
    'colorlight' : lighten($google, 70%),
    'colordark' : lighten($google, 20%),
    'gradient' : linear-gradient($google, $google),
    'gradienthover' : linear-gradient($google, $google),
    'tablegradient' : linear-gradient(#ffffff, #d8d8d8),
    'tabletrodd' : lighten($dark, 97%),
    'tabletreven' : lighten($google, 95%),
    'tablehover' : lighten($dark, 90%),
    'tablehovercolor' : lighten($dark, 5%),
    'tablehoverfa' : lighten($dark, 50%),
    'tableth' : lighten($dark, 35%),
    'tablecolor' : lighten($dark, 25%),
    'tabactive' : lighten($google, 60%),
    'tableborder' : lighten($dark, 85%),
    'tablearrow' : lighten($google, 5%),
    'tablelink' : lighten($google, 1%),
    'btn' : linear-gradient($google, $google),
    'btncolor' : lighten($google, 95%),
    'btnhover' : linear-gradient($google, $google),
    'btnactive' : linear-gradient($google, $google),
    'btncurrent' : linear-gradient($google, $google),
    'btnfocus' : linear-gradient($google, $google),
    'btnborder' : lighten($google, 1%),
    'paginationbtn' : linear-gradient($google, $google),
    'paginationbtncolor' :  lighten($google, 95%),
    'paginationbtnhover' : linear-gradient($google, $google),
    'paginationbtnhovertext' :  lighten($google, 95%),
    'paginationbtnactive' : linear-gradient($google, $google),
    'paginationbtnactivetext' :  lighten($google, 95%),
    'paginationbtncurrent' : linear-gradient($google, $google),
    'paginationbtncurrenttext' :  lighten($google, 95%),
    'paginationbtnfocus' : linear-gradient($google, $google),
    'paginationbtnfocustext' :  lighten($google, 95%),
    'paginationbtnborder' : lighten($google, 1%),
    'colVisbtn' : linear-gradient($google, $google),
    'colVisbtncolor' :  lighten($google, 95%),
    'colVisbtnhover' : linear-gradient($google, $google),
    'colVisbtnactive' : linear-gradient($google, $google),
    'colVisbtncurrent' : linear-gradient($google, $google),
    'colVisbtnfocus' : linear-gradient($google, $google),
    'colVisbtnborder' : lighten($google, 1%),
    'selectbtn' :  lighten($google, 95%),
    'selectbtncolor' : lighten($dark, 70%),
    'selectbtnhover' :  lighten($google, 95%),
    'selectbtnactive' :  lighten($google, 95%),
    'selectbtncurrent' :  lighten($google, 95%),
    'selectbtnfocus' :  lighten($google, 95%),
    'selectbtnborder' : lighten($dark, 85%),
    'paneltrans' :  lighten($google, 95%),
    'paneltranstitle' : lighten($dark, 25%),
    'paneltransborder' : lighten($dark, 85%),
    'paneltransheading' :  lighten($google, 95%),
    'paneltransheadingborder' : lighten($dark, 60%),
    'paneltransheadinglink' : lighten($google, 5%),
    'paneltransbody' :  lighten($google, 95%),
    'paneltransbodytext' : lighten($dark, 50%),
    'paneltransbodyborder' : lighten($dark, 80%),
    'paneltransfooter' : lighten($dark, 95%),
    'paneltransfooterborder' : lighten($dark, 85%),
    'paneltransfootertext' : lighten($dark, 50%),
    'panelgray' : lighten($google, 95%),
    'panelgraytitle' : lighten($dark, 25%),
    'panelgrayborder' : lighten($dark, 85%),
    'panelgrayheading' : lighten($dark, 95%),
    'panelgrayheadinglink' : lighten($dark, 25%),
    'panelgrayheadingborder' : lighten($dark, 85%),
    'panelgraybody' :  lighten($google, 95%),
    'panelgraybodytext' : lighten($dark, 25%),
    'panelgraybodyborder' : lighten($dark, 95%),
    'panelgrayfooter' : lighten($google, 95%),
    'panelgrayfooterborder' : lighten($google, 85%),
    'panelgrayfootertext' : lighten($dark, 25%),
    'modal' :  lighten($google, 95%),
    'modalcontent' :  lighten($google, 95%),
    'modalbackdrop' : lighten($dark, 5%),
    'modalheaderbg' : lighten($dark, 95%),
    'modalheadertext' : lighten($google, 5%),
    'modalheadercolor' : lighten($google, 5%),
    'modalheaderborder' : lighten($dark, 80%),
    'modalbody' :  lighten($google, 95%),
    'modalbodytext' : lighten($dark, 40%),
    'modalbodylink' : lighten($dark, 40%),
    'modalfooterbg' : lighten($dark, 95%),
    'modalfooterborder' : lighten($dark, 80%),
    'modalfootertext' : lighten($dark, 40%),
    'tabsborder' : lighten($dark, 85%),
    'tabsborderactive' : lighten($google, 5%),
    'tabbg' : lighten($dark, 95%),
    'tabbgactive' : lighten($google, 69%),
    'tabtext' : lighten($dark, 20%),
    'tabtextactive' : lighten($google, 5%),
    'lihover' : lighten($dark, 95%),
    'lihovertext' : lighten($dark, 40%),
    'libg' : lighten($dark, 98%),
    'liborder' : lighten($dark, 85%),
    'btnedit' : linear-gradient(#ffffff, #f7f7f7),
    'btnedithover' : linear-gradient(#f7f7f7, #ffffff),
    'btnedittext' : lighten($google, 5%),
    'btnview' : linear-gradient(#ffffff, #f7f7f7),
    'btnviewhover' : linear-gradient(#f7f7f7, #ffffff),
    'btnviewtext' : lighten($google, 5%),
    'btndelete' : linear-gradient(#ffffff, #f7f7f7),
    'btndeletehover' : linear-gradient(#f7f7f7, #ffffff),
    'btndeletetext' : lighten($google, 5%),
    'btnadd' : linear-gradient(#00a569, #00653a),
    'btnaddhover' : linear-gradient(#00653a, #00a569),
    'btnaddtext' : lighten($google, 90%),
    'btnclose' : linear-gradient(#bf0000, #930000),
    'btnclosehover' : linear-gradient(#930000, #bf0000),
    'btnclosetext' : lighten($google, 90%),
    'btndefault' : linear-gradient($google, $google),
    'btndefaulthover' : linear-gradient($google, $google),
    'btndefaulttext' : lighten($google, 90%),
    'btnupload' : linear-gradient(#24b4dc, #0194bd),
    'btnuploadhover' : linear-gradient(#0194bd, #24b4dc),
    'btnuploadtext' : lighten($google, 90%),
    'btnupdate' : linear-gradient(#4e4e4e, #000000),
    'btnupdatehover' : linear-gradient(#fd6f74, $google),
    'btnupdatetext' : lighten($google, 90%),
    'breadcrumb' : lighten($dark, 80%),
    'breadcrumbactive' : lighten($dark, 20%),
    'breadcrumbborder' : lighten($dark, 80%),
    'breadcrumblink' : lighten($dark, 40%),
    'breadcrumbcolor' : lighten($dark, 40%),
    'formcontrol' : lighten($google, 90%),
    'formcontrolborder' : lighten($dark, 85%),
    'formcontrolfocus' : lighten($google, 5%),
    'formcontroltext' : lighten($dark, 25%),
    'inputaddon' : lighten($dark, 85%),
    'inputaddonicon' : lighten($dark, 40%),
    'avatar' : lighten($google, 45%),
    'avatarborder' : lighten($google, 5%),
    'dropdownbg' : lighten($google, 90%),
    'dropdownli' : lighten($google, 90%),
    'dropdownborder' : lighten($dark, 60%),
    'dropdownhover' : lighten($google, 5%),
    'dropdownhovercolor' : lighten($google, 90%),
    'dropdowncolor' : lighten($dark, 20%),
    'dropdownactive' : lighten($google, 5%),
    'dropdownarrow' : lighten($google, 90%),
    'dropdownarrowshadow' : #cccccc,
    'datepickerbg' : lighten($google, 85%),
    'datepickerhead' : lighten($google, 10%),
    'datepickerheadlink' : lighten($google, 90%),
    'datepickerheadtext' : lighten($google, 90%),
    'datesborder' : lighten($dark, 90%),
    'radio' : lighten($google, 5%),
    'checkbox' : lighten($google, 5%),
    'datepickerweek' : lighten($google, 5%),
    'datepickerweektext' : lighten($google, 90%),
    'datepickerweeklink' : lighten($google, 90%),
    'tooltip' : lighten($google, 5%),
    'tooltiparrow' : lighten($google, 5%),
    'tooltiptext' : lighten($google, 90%),
    'filterbg' : lighten($dark, 95%),
    'filterborder' : lighten($dark, 85%),
    'filtertotallabel' : lighten($dark, 35%),
    'filtertotalstrong' : lighten($dark, 35%),
    'filtertext' : lighten($dark, 45%),
    'agodaystext' : lighten($google, 90%),
    'userlogbg' : lighten($google, 90%),
    'userlogname' : lighten($dark, 20%),
    'userlogdesignation' : lighten($dark, 20%),
    'userlogmailid' : lighten($google, 2%),
    'userlogborder' : lighten($dark, 85%),
    'taskdetailsidebarlink' : lighten($google, 90%),
    'taskdetailsidebarborder' : lighten($google, 25%),
    'taskdetailsidebartext' : lighten($google, 80%),
    'employeeproject' : lighten($google, 90%),
    'employeeprojecttitle' : lighten($dark, 20%),
    'employeeprojectborder' : lighten($dark, 85%),
    'everythingdate' : lighten($dark, 90%),
    'everythingdatetext' : lighten($dark, 25%),
    'taskdetailupadte' : lighten($dark, 60%),
    'taskdetailfa' : darken($google, 60%),
    'milestonenote' : darken($google, 60%),
    'milestonenotelabel': darken($google, 60%),
    'milestoneborder' : lighten($dark, 90%),
    'responsiblelabel' : darken($google, 60%),
    'responsibleuser' : darken($google, 60%),
    'taskdetailborder' : lighten($dark, 85%),
    'taskdetaillink' : lighten($google, 2%),
    'taskdetailtext' : lighten($google, 90%),
    'currentlisttitle' : darken($google, 60%),
    'currentlisttext' : darken($google, 60%),
    'currentlistlink' : darken($google, 2%),
    'currentlistfa' : darken($google, 60%),
    'completelisttitle' : darken($google, 60%),
    'completelisttext' : darken($google, 60%),
    'completelistlink' : darken($google, 2%),
    'completelistfa' : darken($google, 60%),
    'taskaddlistborder' : lighten($dark, 90%),
    'taskaddlisticontext' :  lighten($google, 2%),
    'taskaddlisticonbg' :  lighten($dark, 98%),
    'taskaddlisticonborder' :  lighten($dark, 85%),
    'filtertotaltimeborder' : lighten($dark, 85%),
    'viewuserprofileborder' : lighten($dark, 85%),
    'rolebg' : lighten($google, 1%),
    'rolecolor' : lighten($google, 90%),
    'sliderselection' : linear-gradient(#e4e0e4, #cfcace),
),
) !default;



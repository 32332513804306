.breadcrumb{
    display: table;
    width: 100%;
    padding: 0;
    font-size: 14px;
    background-color: transparent;
    li {
        @include color('breadcrumbcolor');
        @include background-color('breadcrumb');
        float: left;
        position: relative;
        margin-right: 18px;
        a {
            display: block;
            padding: 0px;
            font-size: 12px;
            font-weight: 600;
            height: 30px;
            line-height: 30px;
            text-transform: uppercase;
            @include color('breadcrumbactive');
            @include background-color('breadcrumb');
        }
        span{
        	padding: 0px 10px;
        	display: block;
        	font-size: 12px;
        	font-weight: 600;
        	height: 30px;
        	line-height: 30px;
        	text-transform: uppercase;
            @include color('breadcrumblink');
            @include background-color('breadcrumb');
        }
        &:first-child{
        	a{
        		span{
                    padding: 0px 10px;
                }
        	}
        }
        &:after{
        	content: "";
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid;
            position: absolute;
			right: -15px;
			top: 0;
            @include border-left-color('breadcrumbborder');
        }
        &:before{
        	content: "";
			position: absolute;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 15px solid;
            border-bottom: 15px solid;
            @include border-top-color('breadcrumbborder');
            @include border-bottom-color('breadcrumbborder');
			left: -15px;
			top: 0;
        }
        &:first-child{
        	&:before{
        		border: 0px;
        	}
        }
        &.active{
            span{
                color: #fff;
                @include background-color('base');
            }
            &:after{
                @include border-left-color('base');
            }
            &:before{
                @include border-top-color('base');
                @include border-bottom-color('base');
            }
        }
    }
}
.breadcrumb>li+li:before{
	content: '';
	padding: 0px;
}
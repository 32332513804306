.black {
    &.changetheme{
        .page-container {
            background-color: #fafafa !important;
        }
    }
}
.thmepage {
    .panel {
        .panel-body {
            padding: 15px;
        }
    }
}

.theme-tab{
    .nav-tabs{
        margin-bottom: 0px;
    }
    .tab-content{
        padding: 15px 0px;
        margin-bottom: 15px;
    }
	.list-inline{
		border-bottom: 1px solid #d1d1d1;
		li{
			padding: 8px;
			text-transform: uppercase;
			font-weight: 600;
			border-top: 5px solid #fff;
			background-color: #fff;
			&.active{
				border-top: 5px solid red;
			}
		}
	}
}
.theme_list {
    padding: 0px;
    margin: 0;
    width: 100%;
    list-style: none;
    li {
    	display: inline-block;
    	float: none;
        a {
            display: block;
        }
        span {
            width: 30px;
            height: 30px;
            display: block;
            @include border-radius(100%);
        }
        h2 {
            display: block;
            text-align: center;
            @include font-size(14px);
            text-transform: uppercase;
            font-weight: 600;
            margin-top: 10px;
            color: #444;
        }
    }
}

.theme-body {
    position: relative;
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    iframe {
        border: 0;
        width: 100%;
        height: 700px;
    }
}

.theme_structure {
    border: 1px solid #d9d9d9;
    width: 100%;
    .header {
        height: 50px;
        position: relative;
        padding: 15px;
        z-index: inherit;
    }
    .page-header {
        padding: 15px;
    }
    .content-part {
        padding: 15px;
        margin-left: 200px;
    }
    .body {
        position: relative;
        @include background-color('bodybg');
    }
    .page-sidebar {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 200px;
        z-index: 990;
    }
    .footer {
        position: relative;
        padding: 15px;
        border-top: 1px solid #f1f1f1;
    }
}
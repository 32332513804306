div.dropdown-menu {
    width: 200px;
    padding: 0;
    margin: 0px;
    border: 1px solid transparent;
    //@include border-color('dropdownborder');
    @include background-color('dropdownbg');
    @include border-radius(3px);
    @include animation('fadeInUp .5s ease-in-out');
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    li {
        float: none;
        display: block;
        margin: 0px;
        @include background-color('dropdownli');
        span {
            padding: 5px 10px;
            display: block;
            text-transform: capitalize;
            @include font-size(16px);
            @include color('dropdowncolor');
            &:hover,
            &:focus {
                @include background-color('dropdownhover');
                @include color('dropdownhovercolor');
            }
        }
        span.text{
            padding: 0px;
        }
        a {
            padding: 5px 10px;
            display: block;
            text-transform: capitalize;
            @include font-size(16px);
            @include color('dropdowncolor');
            &:hover,
            &:focus {
                @include background-color('dropdownhover');
                @include color('dropdownhovercolor');
            }
        }
        &.active {
            a {
                @include background-color('dropdownhover');
                @include color('dropdownhovercolor');
                &:hover,
                &:focus {
                    @include background-color('dropdownhover');
                    @include color('dropdownhovercolor');
                }
            }
        }
        &.selected{
            a{
                color: #fff;
            }
        }
    }
}
.dropdown {
    position: relative;
    .dropdown-menu {
        width: 200px;
        padding: 0;
        margin: 0px;
        border: 1px solid transparent;
        //@include border-color('dropdownborder');
        @include background-color('dropdownbg');
        @include border-radius(3px);
        @include animation('fadeInUp .5s ease-in-out');
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        li {
            float: none;
            display: block;
            margin: 0px;
            @include background-color('dropdownli');
            span {
                padding: 5px 10px;
                display: block;
                text-transform: capitalize;
                @include font-size(16px);
                @include color('dropdowncolor');
                &:hover,
                &:focus {
                    @include background-color('dropdownhover');
                    @include color('dropdownhovercolor');
                }
            }
            span.text{
                padding: 0px;
            }
            a {
                padding: 5px 10px;
                display: block;
                text-transform: capitalize;
                @include font-size(16px);
                @include color('dropdowncolor');
                &:hover,
                &:focus {
                    @include background-color('dropdownhover');
                    @include color('dropdownhovercolor');
                }
            }
            &.active {
                a {
                    @include background-color('dropdownhover');
                    @include color('dropdownhovercolor');
                    &:hover,
                    &:focus {
                        @include background-color('dropdownhover');
                        @include color('dropdownhovercolor');
                    }
                }
            }
        }
    }
    &.padd{
        .dropdown-menu{
            padding: 10px 0px;
            li{
                display: block;
                padding: 5px 10px;
            }
        }
    }
    &.drop-arrow {
        .dropdown-menu {
            &:before {
                content: '';
                position: absolute;
                border-bottom: 8px solid;
                @include border-bottom-color('dropdownarrow');
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                margin-top: -8px;
                width: 8px;
                height: 8px;
                left: 5px;
                z-index: 10;
                top: 0;
            }
            &:after {
                content: '';
                position: absolute;
                border-bottom: 8px solid;
                @include border-bottom-color('dropdownarrowshadow');
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                margin-top: -8px;
                width: 8px;
                height: 8px;
                left: 5px;
                z-index: 9;
                top: -1px;
            }
        }
    }
    &.rightside {
        .dropdown-menu {
            left: inherit;
            right: 0;
            &:before {
                right: 5px;
                left: inherit;
            }
            &:after {
                right: 5px;
                left: inherit;
            }
        }
    }
}

.bootstrap-select {
    .task-listed {
        padding: 0px;
    }
    .btn {
        background-image: none;
        &.dropdown-toggle {
            background-image: none;
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 12px;
            @include font-size(14px);
            line-height: 1.42857143;
            border: 1px solid;
            @include border-radius(4px);
            @include font-size(14px);
            @include background-color('selectbtn');
            @include border-color('selectbtnborder');
            .filter-option {
                float: none;
            }
            .bs-caret {
                @include color('selectbtncolor');
            }
            &:hover,
            &:focus {
                background-image: none;
                @include background-color('selectbtnhover');
            }
        }
        &.btn-default {
            background-image: none;
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 12px;
            @include font-size(14px);
            line-height: 1.42857143;
            border: 1px solid;
            @include border-radius(4px);
            @include font-size(14px);
            @include background-color('selectbtn');
            @include border-color('selectbtnborder');
            .filter-option {
                color: #000;
            }
            .bs-caret {
                @include color('selectbtncolor');
            }
            &:hover,
            &:focus {
                background-image: none;
                @include background-color('selectbtnhover');
                outline: none;
            }
        }
    }
    &.open {
        .btn-default {
            @include background-color('selectbtn');
            &:hover,
            &:focus {
                @include background-color('selectbtnhover');
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
}

.dropdown-multiselect {
    display: block;
    .dropdown-menu{
        top: 33px;
        width: 100%;
        min-width: inherit;
    }
    .btn {
        background-image: none;
        &.dropdown-toggle {
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 12px;
            line-height: 1.42857143;
            border: 1px solid;
            text-align: left;
            position: relative;
            border: 1px solid;
            @include border-radius(4px);
            @include font-size(14px);
            @include background-color('selectbtn');
            @include border-color('selectbtnborder');
            .caret {
                position: absolute;
                right: 10px;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
        &.btn-default {
            background-image: none;
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 12px;
            @include font-size(14px);
            line-height: 1.42857143;
            border: 1px solid;
            @include border-radius(4px);
            @include font-size(14px);
            @include background-color('selectbtn');
            @include border-color('selectbtnborder');
            @include color('selectbtncolor');
            .filter-option {
                @include color('selectbtncolor');
            }
            .bs-caret {
                @include color('selectbtncolor');
            }
            &:hover,
            &:focus {
                background-image: none;
                @include background-color('selectbtnhover');
                outline: none;
            }
        }
    }
    &.open {
        .btn-default {
            @include background-color('selectbtn');
            &:hover,
            &:focus {
                @include background-color('selectbtnhover');
                outline: none;
            }
        }
    }
}

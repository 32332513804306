[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

.page-user-log {
    padding: 15px;
    margin: 0px 15px 30px;
    position: relative;
    border: 1px solid;
    @include border-color('userlogborder');
    @include background-color('userlogbg');
    .user-pic {
        display: inline-block;
        margin-right: 15px;
    }
    .user-detail {
        display: inline-block;
        vertical-align: middle;
        .name {
            display: block;
            @include font-size(16px);
            font-family: 'pfdintextpro';
            font-weight: 700;
            text-transform: uppercase;
            @include color('userlogname');
        }
        .designation {
            display: block;
            @include font-size(14px);
            @include color('userlogdesignation');
        }
        .mailid {
            display: block;
            @include font-size(14px);
            @include color('userlogmailid');
            a {
                @include color('userlogmailid');
            }
        }
    }
    .roles{
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: table;
        padding: 10px;
        font-weight: 600;
        text-transform: uppercase;
        @include background-color('rolebg');
        @include color('rolecolor');
    }
}

.taskname {
    &.task_name-true {
        text-decoration: line-through;
    }
}

.home_page {
    padding: 0px;
}

.mCustomScrollbar {
    padding: 15px;
}

.employee_project_clients {
    .box {
        padding: 10px 15px 10px;
        border: 1px solid;
        @include background-color('employeeproject');
        @include border-color('employeeprojectborder');
        position: relative;
        .icons {
            position: absolute;
            right: 20px;
            bottom: 0;
            width: 70px;
        }
        .count {
            display: block;
            font-size: 50px;
            font-weight: bold;
            line-height: 50px;
            &.employee {
                color: #009093;
            }
            &.projects {
                color: #04a5cf;
            }
            &.clients {
                color: #f2c217;
            }
        }
        .title{
            a{
                &.employee {
                    color: #009093;
                }
                &.projects {
                    color: #04a5cf;
                }
                &.clients {
                    color: #f2c217;
                }
            }
        }
    }
}

.page_404 {
    position: relative;
    .bg {
        width: 100%;
        height: 100%;
        z-index: 90;
        position: relative;
    }
    .wrap {
        max-width: 500px;
        height: 320px;
        @include opacity($white, 0.9);
        padding: 40px 20px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 100;
        h1 {
            @include font-size(100px);
            display: block;
            margin-bottom: 30px;
            font-weight: 600;
        }
        h2 {
            @include font-size(22px);
            display: block;
            margin-bottom: 30px;
            font-weight: 600;
        }
        p {
            @include font-size(16px);
        }
        a {
            color: red;
        }
        img {
            display: block;
            margin: 0 auto 30px;
        }
    }
}

.page_503 {
    position: relative;
    .bg {
        width: 100%;
        height: 100%;
        z-index: 90;
        position: relative;
    }
    .wrap {
        max-width: 500px;
        height: 395px;
        @include opacity($white, 0.9);
        padding: 40px 20px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 100;
        h1 {
            @include font-size(100px);
            display: block;
            margin-bottom: 30px;
            font-weight: 600;
        }
        h2 {
            @include font-size(22px);
            display: block;
            margin-bottom: 30px;
            font-weight: 600;
        }
        p {
            @include font-size(16px);
        }
        a {
            color: red;
        }
        img {
            display: block;
            margin: 0 auto 30px;
        }
    }
}

.agodays {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    line-height: 25px;
    display: table;
    @include color('agodaystext');
    @include background-color('agodays');
    padding: 0 10px;
    @include font-size(12px);
    text-transform: uppercase;
}

.project_listing {
    li {
        position: relative;
        list-style: none;
        padding: 15px;
        border-top: 1px solid;
        @include border-color('liborder');
        @include background-color('libg');
        @include color('light');
        a {
            @include color('link');
        }
        .project_task_detail {
            float: left;
            margin-top: 5px;
            .fa {
                display: inline-block;
                margin-right: 10px;
                @include font-size(14px);
                @include color('taskdetailfa');
            }
            .project_name {
                @include font-size(16px);
                text-transform: capitalize;
                .update {
                    display: inline-block;
                    margin-left: 10px;
                    padding-left: 20px;
                    position: relative;
                    @include color('taskdetailupadte');
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 15px;
                        height: 2px;
                        @include background-color('taskdetailupadte');
                        @include color('taskdetailupadte');
                        top: -2px;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
        &:hover {
            @include background-color('lihover');
            @include color('lihovertext');
        }
        &:first-child {
            border-top: 0px;
        }
    }
}

.actions {
    float: right;
}

.task_listing {
    padding: 0;
    margin: 0;
    li {
        position: relative;
        list-style: none;
    }
}

.task_add_list {
    padding: 10px 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid;
    position: relative;
    @include border-bottom-color('taskaddlistborder');
    .dropdown{
        span{
            cursor: pointer;
        }
    }
    li {
        list-style: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-right: 5px;
        .task_user {}
        .task_sub_user {
            display: none;
            color: red;
            padding: 5px 10px;
            position: relative;
            .tskuser {
                display: inline-block;
                vertical-align: top;
            }
            &:before {
                content: '';
                position: absolute;
                border-bottom: 5px solid red;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                width: 0;
                height: 0;
                top: -5px;
                left: 10px;
            }
        }
        &:hover {
            .task_sub_user {
                display: block;
            }
        }
    }
    label {
        font-weight: normal;
        margin-bottom: 0px;
    }
    .addEntery {
        display: none;
        // width: 120px;
    }
    .timer {
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        cursor: pointer;
        border: 1px solid;
        @include border-color('taskaddlisticonborder');
        @include background-color('taskaddlisticonbg');
        @include color('taskaddlisticontext');
        .fa {
            line-height: 25px;
            @include font-size(14px);
            @include color('taskaddlisticontext');
        }
    }
    .logtimer {
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        cursor: pointer;
        border: 1px solid;
        @include border-color('taskaddlisticonborder');
        @include background-color('taskaddlisticonbg');
        @include color('taskaddlisticontext');
        .fa {
            line-height: 25px;
            @include font-size(14px);
            @include color('taskaddlisticontext');
        }
    }
    .edit {
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        cursor: pointer;
        border: 1px solid;
        @include border-color('taskaddlisticonborder');
        @include background-color('taskaddlisticonbg');
        @include color('taskaddlisticontext');
        .fa {
            line-height: 25px;
            @include font-size(14px);
            @include color('taskaddlisticontext');
        }
    }
    .trash {
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        cursor: pointer;
        border: 1px solid;
        @include border-color('taskaddlisticonborder');
        @include background-color('taskaddlisticonbg');
        @include color('taskaddlisticontext');
        .fa {
            line-height: 25px;
            @include font-size(14px);
            @include color('taskaddlisticontext');
        }
    }
    &:hover {
        .addEntery {
            display: block;
        }
    }
    &.no-border {
        border-bottom: 0px;
        padding: 0px;
    }
    &.additional{
        margin-bottom: 0px;
    }
}

.dates {
    display: block;
    border: 1px solid;
    @include border-color('datesborder');
    @include border-radius(5px);
    .month {
        display: block;
        padding: 5px;
        text-align: center;
        @include font-size(16px);
        text-transform: uppercase;
        background-color: #e8bc69;
    }
    .body {
        display: block;
        @include background-color('light');
        text-align: center;
        padding: 10px 10px 0px;
        @include border-radius(0px);
        .date {
            @include font-size(50px);
            display: block;
            line-height: 50px;
        }
        .day {
            display: block;
            margin: 0 -10px;
            @include font-size(14px);
            background-color: #f1f1f1;
            @include color('dark');
            padding: 5px;
            text-transform: uppercase;
             @include border-radius(5px);
        }
    }
}

.milestone_list {
    padding: 0 0 15px;
    margin: 0;
    li {
        list-style: none;
        margin: 0 0 15px;
        padding-bottom: 15px;
        position: relative;
        border-bottom: 1px solid;
        @include border-bottom-color('milestoneborder');
        &:last-child {
            border-bottom: 0px;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
    }
    .data {
        position: relative;
        min-height: 140px;
    }
    .notes {
        padding: 0;
        margin: 0;
        display: block;
        width: 100%;
        p {
            @include font-size(14px);
            @include color('milestonenote');
            text-transform: capitalize;
            label {
                @include color('milestonenotelabel');
            }
        }
    }
    .complete {
        font-weight: bold;
        color: green;
    }
    .uncomplete {
        font-weight: bold;
        color: red;
    }
    .checkbox {
        margin-bottom: 10px;
    }
    .discription {
        display: block;
        margin-bottom: 10px;
    }
    .responsible {
        @include color('responsibleuser');
        label {
            font-weight: 600;
            @include color('responsiblelabel');
        }
        .user {
            border-right: 1px solid;
            @include border-right-color('text');
            margin-right: 10px;
            padding-right: 5px;
            &:last-child {
                border-right: 0px;
            }
        }
    }
    .action {
        display: none;
        a {
            margin-right: 5px;
            display: inline-block;
            cursor: pointer;
        }
    }
    .data {
        &:hover {
            .action {
                display: block;
            }
        }
    }
}
.add_project_people_modal{
    .modal-lg{
        max-width: 900px;
        width: 90%;
    }
}
.add_project_people {
    padding: 0;
    margin: 30px 0 0 0;
    &.add_project_people_list{
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                width: 25%;
                padding: 8px;
                border: 0;
                margin: 0;
                @include breakpoint( max , 991){
                    width: 33.33%;
                }
                @include breakpoint( max , 767){
                    width: 50%;
                }
                .avtar{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    box-shadow: 0px 0 0px 2px rgba(0, 0, 0, 0.1) !important;
                    .img{
                        margin: 0;
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                        border-radius: 0;
                        box-shadow: none;
                        background: #f1f1f1;
                        width: 50px;
                        height: 50px;
                        img{
                            border: 0;
                            border-right: 1px solid rgba(0,0,0,0.1);
                            width: 50px;
                            height: 50px;
                            -webkit-border-radius: 5px 0 0 5px;
                            -moz-border-radius: 5px 0 0 5px;
                            border-radius: 5px 0 0 5px;
                        }
                    }
                    .name{
                        width: calc(100% - 50px);
                        padding-left: 10px;
                        padding-right: 5px;
                        line-height: 1.2;
                        text-align: left;
                        color: #333;
                        @include breakpoint( max , 767){
                            font-size: 14px;
                        }
                    }
                }    
                .checkbox{
                    left: 4px;
                    top: 4px;
                    label{
                        width: 20px;
                        height: 20px;
                        &:after{                            
                            width: 20px;
                            height: 20px;
                            line-height: 20px;
                            font-size: 24px !important;
                        }
                    }
                    input[type=checkbox]:checked + label:after{
                        background: #ffffff;
                    }
                }            
            }
        }
    }
    li {
        list-style: none;
        position: relative;
        margin: 10px 0px;
    }
    .checkbox {
        position: absolute;
        top: 0px;
        left: 20px;
    }
    .checkbox label:after {
        content: "";
        font-family: 'FontAwesome';
        @include font-size(20px);
    }
    .checkbox input[type=checkbox]:checked + label:after {
        content: '\f14a';
        font-family: 'FontAwesome';
        color: green;
    }
}

.filter-total-time {
    padding: 10px;
    margin: 0px;
    border-bottom: 1px solid;
    @include border-bottom-color('filtertotaltimeborder');
    @include background-color('filterbg');
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            display: inline-block;
            vertical-align: top;
            @include color('filtertext');
        }
        label {
            font-weight: 600;
            margin-right: 10px;
            @include color('filtertotallabel');
        }
        span {
            display: inline-block;
            font-weight: 600;
            margin-right: 10px;
            @include color('filtertotallabel');
        }
    }
}

.complete-task {
    padding: 0;
    margin: 0;
    .head {
        text-decoration: line-through;
        margin-bottom: 15px;
    }
    span {
        text-transform: uppercase;
        display: block;
        margin-bottom: 15px;
    }
    ul {
        padding: 0;
        margin: 0 0 0 1em;
        li {
            list-style: disc;
            padding: 5px 0px;
            a {
                //text-decoration: line-through;
            }
        }
    }
}

.current-projects {
    padding: 0;
    margin: 0;
    .head {
        margin-bottom: 15px;
    }
    span {
        text-transform: uppercase;
        display: block;
        margin-bottom: 15px;
    }
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            padding: 5px 0px;
            a {
                .fa {
                    color: red;
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }
    }
}

.all_view {
    .list_view {
        display: block;
    }
    .list_grid_view {
        display: none;
    }
    .list_box_view {
        display: none;
        .panel {
            @include font-size(16px);
            margin-bottom: 20px;
            h2 {
                font-family: 'pfdintextpro';
                font-weight: 700;
                @include font-size(16px);
                text-transform: uppercase;
                line-height: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
            }
            .panel-body {
                padding: 15px;
                height: 150px;
                .list {
                    display: block;
                    margin: 5px 0px;
                }
            }
        }
    }
}

.view_user_profile {
    .pic-name {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid;
        @include border-bottom-color('viewuserprofileborder');
    }
    .name {
        display: inline-block;
        vertical-align: middle;
        font-family: 'pfdintextpro';
        font-weight: 700;
        @include font-size(16px);
        text-transform: uppercase;
        margin-left: 10px;
        @include color('userlogname');
        .designation {
            font-family: 'pfdintextpro';
            font-weight: 200;
            @include font-size(12px);
            text-transform: none;
            display: block;
            @include color('userlogdesignation');
        }
    }
    .nav-pills {
        li {
            width: 49%;
            padding: 0 15px;
            margin: 8px 0px;
        }
    }
    fieldset {
        border: 1px solid;
        @include border-color('viewuserprofileborder');
        padding: 10px;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0px;
        }
        legend {
            font-family: 'pfdintextpro';
            font-weight: 700;
            @include font-size(16px);
            @include color('base');
            text-transform: uppercase;
            width: auto;
            border: 0px;
            padding: 0 15px;
            margin: 0px;
        }
        .form-horizontal {
            padding: 0 15px;
        }
        .row {
            padding: 5px 0px;
            margin: 0px;
        }
    }
}

.icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;
    color: #ffffff;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    .fa {
        @include font-size(14px);
        line-height: 30px;
    }
    &.google {
        background-color: $google;
    }
    &.facebook {
        background-color: $facebook;
    }
    &.globe {
        background-color: $globe;
    }
    &.linkedin {
        background-color: $linkedin;
    }
    &.skype {
        background-color: $skype;
    }
    &.twitter {
        background-color: $twitter;
    }
}

.photo-upload {
    position: relative;
    #filelist {
        display: inline-block;
    }
    #progressbar {
        display: inline-block;
    }
    #container {
        display: inline-block;
    }
}

.task-detail-sidebar {
    li {
        list-style: none;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    label {
        font-weight: 400;
        @include font-size(16px);
        display: block;
        position: relative;
        margin: 0;
        border-bottom: 1px solid;
        text-transform: uppercase;
        padding-bottom: 5px;
        margin-bottom: 10px;
        @include color('taskdetailsidebartext');
        @include border-bottom-color('taskdetailsidebarborder');
    }
    span {
        font-weight: 400;
        @include font-size(14px);
        display: block;
        padding: 8px;
        text-transform: capitalize;
        @include color('taskdetailsidebartext');
    }
    a{
        @include color('taskdetailsidebarlink');
    }
}

.no-filter {}

.everything-date {
    display: block;
    margin-bottom: 15px;
    @include background-color('everythingdate');
    padding: 10px;
    h2 {
        @include font-size(16px);
        font-weight: 600;
        @include color('everythingdatetext');
    }
}

.task-discription {
    width: 150px;
}

.name {
    &.name-false {}
    &.name-true {
        text-decoration: line-through;
    }
}

.filtter {
    padding: 15px 0px 0px;
    margin: 0px;
    border: 1px solid;
    @include background-color('filterbg');
    @include border-color('filterborder');
    label {
        @include font-size(12px);
        text-transform: uppercase;
    }
    .form-group {
        padding-bottom: 15px;
        .form-group {
            padding-bottom: 0px;
        }
    }
    .action{
        padding-top: 8px;
    }
}

.filter-total {
    padding-top: 10px;
    p {
        @include font-size(14px);
        @include color('filtertext');
    }
    strong {
        font-weight: 600;
        display: inline-block;
        margin-left: 5px;
        @include color('base');
    }
    label {
        font-weight: 600;
        display: inline-block;
        margin-right: 5px;
        @include color('base');
    }
    &.everything{
        padding: 15px;
        @include gradients('tablegradient');
        margin-bottom: 15px;
    }
}
.hours-count{
    label{
        display: inline-block;
        @include color('base');
    }
    strong{
        @include color('base');
    }
    .logged-hours{
        display: inline-block;
    }
    .non-billable-hours{
        display: inline-block;
    }
    .billable-hours{
        display: inline-block;
    }
}

.current-task-list{
    span{
        display: block;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: $pfdintextpro;
        @include color('currentlisttitle');
    }
    li{
        padding: 5px 0px;
        display: block;
        @include color('currentlisttext');
        a{
            @include color('currentlistlink');
        }
        .fa{
            @include color('currentlistfa');
            display: inline-block;
            margin-right: 10px;
        }
    }
}

.complete-task-list{
    span{
        display: block;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: $pfdintextpro;
        @include color('completelisttitle');
    }
    ul{
        li{
            padding: 5px 0px;
            display: block;
            @include color('completelisttext');
            a{
                @include color('completelistlink');
            }
            .fa{
                @include color('completelistfa');
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}

.category-wise-task{
    span{
        display: block;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: $pfdintextpro;
        @include color('completelisttitle');
    }
    li{
        padding: 5px 0px;
        display: block;
        @include color('completelisttext');
        a{
            @include color('completelistlink');
        }
        .fa{
            @include color('completelistfa');
            display: inline-block;
            margin-right: 10px;
        }
    }
}

.admin-key{
    position: absolute;
    top: 0;
    right: -10px;
    @include border-radius(100%);
    text-align: center;
    width: 20px;
    height: 20px;
    z-index: 10;
    @include background-color('base');
    .fa{
        @include font-size(14px);
        color: #fff;
    }
}

.task-additional-detail{
    label{
        @include color('base');
    }
}
.slider-wrap{
    width: 100%;
    padding: 0 10px;
    position: relative;    
}
.slider.slider-horizontal{
    width: 100%;
    display: block;
    margin: 0 auto;
    .slider-track{
        width: 100%;
    }
    .slider-handle{
       @include gradients('btndefault');
    } 
    .slider-selection{
        @include gradients('sliderselection');
    }
}
.workload_label{
    @include gradients('sliderselection');
    color: #fff;
    padding: 5px;
    display: inline-block;
    font-size: 12px;
    line-height: 0.85;
    @include border-radius(3px);
    position: relative;
    color: #000;
    &:before{
        content: '';
        position: absolute;
        border-right: 10px solid;
        border-color: #d6d2d5;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        left: -9px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.per-profile{
    padding: 15px;
    margin: 0 auto;
    max-width: 700px;
    width: 100%;
    .name-pic{
        display: block;
        width: 100%;
        margin-bottom: 20px;
        border-bottom: 1px solid #d1d1d1;
        padding-bottom: 20px;
        .avtar{
            display: inline-block;
            vertical-align: bottom;
            margin-right: 10px;
        }
        .name-post{
            display: inline-block;
            text-transform: uppercase;
            padding-top: 20px;
            .name{
                display: block;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
            }
            .post{
                display: block;
                font-size: 14px;
                font-weight: 400;
                text-transform: inherit;
            }
        }
    }
    .detail{
        .detail-group{
            display: block;
            width: 100%;
            background-color: #fff;
            border: 1px solid #f1ebeb;
            padding: 15px;
            margin-bottom: 20px;
            .form-group{
                display: block;
                label{
                    display: inline-block;
                    width: 150px;
                }
                .input-detail{
                    display: inline-block;
                }
                .currentp{
                    padding: 8px;
                    background-color: orange;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 700;
                    min-width: 100px;
                    display: block;
                    width: 100%;
                    text-align: center;
                }
                .completedp{
                    padding: 8px;
                    background-color: green;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 700;
                    min-width: 100px;
                    display: block;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        fieldset{
            display: block;
            width: 100%;
            position: relative;
            border: 1px solid #f1ebeb;
            padding: 30px 15px 15px;
            legend{
                position: absolute;
                left: 20px;
                top: -14px;
                font-size: 16px;
                font-weight: 700;
                background-color: #fff;
                display: table;
                padding: 5px 15px;
                width: auto !important;
                border: 0px;
                text-transform: uppercase;
            }
        }
    }
}